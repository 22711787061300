<template>
  <div align="left">
    <div class="card">
      <div class="flex justify-content-between pb-2">
        <div class="col flex mb-1">
          <i
            @click="closeEstimate"
            style="color: #778ca2"
            class="las text-20 la-angle-left pt-1 pr-1 bluehover cursor-pointer"
          />
          <h1 class="flex align-items-center mr-3 font-light">
            Estimate:&nbsp;
            <h1 v-if="!isLoading">
              &nbsp;{{ stateInvoice?.invoice?.document_number }}
            </h1>
            <Skeleton
              width="100px"
              height="24px"
              borderRadius="6px"
              v-else
            />
            <span class="text-12 pl-4 mr-3">
              <span
                v-if="!isLoading"
                :style="getTagStyle() + ' borderRadius: 4px'"
                class="p-2 py-1 uppercase"
              >
                {{ stateInvoice?.invoice?.status }}
              </span>
              <Skeleton
                width="52px"
                height="21px"
                borderRadius="4px"
                v-else
              />
            </span>
          </h1>
        </div>

        <div
          v-if="stateInvoice?.invoice?.status !== 'approved'"
          class="pr-2"
        >
          <Button
            v-if="!isLoading"
            :loading="isApproving"
            label="Activate Tasks"
            @click="approveEstimate"
            class="p-button-primary"
            :disabled="isApproving"
          />
        </div>
        <div>
          <Button
            v-if="!isLoading"
            :loading="downloading"
            class="p-button-secondary"
            @click.stop="getPdf(uid)"
            label="Download"
            icon="las la-cloud-download-alt"
          />
        </div>
        <div class="flex text-center align-items-center">
          <div class="ml-3">
            <Skeleton
              height="40px"
              width="80px"
              borderRadius="6px"
              v-if="isLoading"
            />
            <template v-else>
              <Button
                v-if="stateInvoice?.invoice?.status === 'issued'"
                class="p-button-warning"
                label="Issued"
                disabled
              />
            </template>
          </div>
        </div>
      </div>
      <Divider class="mt-0 mb-2"></Divider>
      <div class="pt-1">
        <!-- Main parent div for the estimate details.
             We add the .edit-icon here so that it shows on hover -->
        <div class="parent-div">
          <!-- This icon will appear on hover (see CSS) -->
          <div class="edit-icon">
            <i
              @click.stop="openEditDialog"
              class="las la-pen i-18 cursor-pointer grey"
            ></i>
          </div>

          <div class="p-fluid formgrid grid">
            <div class="col-12">
              <div class="p-fluid formgrid grid justify-content-start p-2">
                <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                  <label class="font-light">Date Created</label>
                  <div v-if="!isLoading">
                    {{ formatDate(stateInvoice?.invoice.date_created) }}
                  </div>
                  <Skeleton
                    height="18px"
                    borderRadius="6px"
                    v-else
                  />
                </div>
                <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                  <label class="font-light">Terms</label>
                  <div v-if="!isLoading">{{stateInvoice?.invoice?.terms ?? 'Cash on Delivery'}}</div>
                  <Skeleton
                    height="18px"
                    borderRadius="6px"
                    v-else
                  />
                </div>
                <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                  <label class="font-light">Valid Until</label>
                  <div v-if="!isLoading">{{stateInvoice?.invoice?.date_due}}</div>
                  <Skeleton
                    height="18px"
                    borderRadius="6px"
                    v-else
                  />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="field col">
                <label class="font-light">Description</label>
                <div
                  v-if="!isLoading"
                  v-html="stateInvoice?.invoice?.description"
                ></div>
                <Skeleton
                  height="18px"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>
          </div>
          <div
            class="flex justify-content-between align-items-center my-3"
            style="font-size: 18px"
          >
            <div>
              <Skeleton
                v-if="isLoading"
                height="22px"
                borderRadius="6px"
                width="100px"
                class="ml-1 mt-2"
              ></Skeleton>
              <p
                v-else
                class="p-t-12 p-2"
                style="font-size: 18px"
              >
                {{ stateInvoice?.invoice?.name ?? 'Tasks & Costs' }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="isLoading">
          <Skeleton
            class="mb-3"
            v-for="i in 4"
            :key="i"
            height="36px"
            borderRadius="6px"
          />
        </div>

        <template v-else>
          <InvoiceTaskCost
            :groups="typeItems"
            :jobId="jobId"
            :canSelect="false"
          ></InvoiceTaskCost>
        </template>
      </div>

      <TotalTableFooter
        :subTotal="stateInvoice?.totals?.sub"
        :taxRate="stateInvoice?.totals?.tax"
        :profitMargin="stateInvoice?.totals?.margin"
        :total="stateInvoice?.totals?.total"
        :currencySymbol="job?.client?.currency?.description"
        v-if="stateInvoice?.totals"
        :canSelect="true"
      ></TotalTableFooter>
    </div>

    <!-- Existing dialog for selecting items (unchanged) -->
    <Dialog
      header="Select items"
      contentStyle="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;max-width:1400px"
      :visible.sync="displayAddItems"
      :modal="true"
      :closable="true"
    >
      <template v-if="jobItems">
        <InvoiceTaskCost
          :groups="jobItems"
          @dismiss="displayAddItems = false"
          :jobId="jobId"
          :dismissableMask="true"
          notInvoiced
        ></InvoiceTaskCost>
      </template>
    </Dialog>

    <!-- New Edit Dialog -->
    <Dialog
      header="New Cost Estimate"
      contentStyle="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;max-width:1400px;min-height:500px"
      :visible.sync="estimateDialog"
      :modal="true"
      :closable="true"
      :dismissableMask="true"
    >
      <div class="formgrid p-fluid grid mb-2 mt-1">
        <div class="field col-12 sm:col-4">
          <label for="name">Cost Estimate Name</label>
          <div class="p-inputgroup">
            <InputText
              type="text"
              v-model="ceName"
              class="p-column-filter"
              placeholder="Cost Estimate Name"
            />
          </div>
        </div>
        <div class="field col-12 sm:col-4">
          <label for="name">Valid Until</label>
          <div class="p-inputgroup">
            <Calendar
              id="icon"
              autocomplete="off"
              v-model="validUntil"
              :showIcon="true"
              :selectOtherMonths="true"
              :showOtherMonths="true"
              dateFormat="yy-mm-dd"
              placeholder="Valid Until"
            />
          </div>
        </div>
        <div class="field col-12 sm:col-4">
          <label for="name">Payment Terms</label>
          <div class="p-inputgroup">
            <Dropdown
              v-if="!isLoading && (terms === 'Cash on Delivery' ||terms === '14 Days' || terms === '30 Days' || terms === '60 Days')"
              placeHolder="Payment Terms"
              :options="clientTerms"
              v-model="terms"
              optionLabel="description"
              optionValue="uid"
            />
            <span
              class="p-input-icon-right"
              v-else-if="!isLoading"
            >
              <InputText
                placeholder="Custom Terms"
                v-model="terms"
                style="width: 100%;"
              />
              <i
                class="las la-times cursor-pointer"
                @click="terms = 'Cash on Delivery'"
              ></i>
            </span>
            <Skeleton
              height="100%"
              borderRadius="6px"
              v-else
            />
          </div>
        </div>
        <div class="field col-12">
          <label for="name">Description</label>
          <Textarea
            ref="editor"
            v-model="ceDescription"
            :autoResize="true"
          ></Textarea>
        </div>
      </div>
      <div class="flex justify-content-end mt-3">
        <Button
          label="Cancel"
          @click="estimateDialog = false"
          class="p-button-secondary mr-2"
        />
        <Button
          label="Save"
          @click="saveEdit"
          class="p-button-primary"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import {
  state,
  fetchInvoice,
  fetchJobItems,
} from "../../../services/data_service.js";
import TotalTableFooter from "@/application/Finance/components/TotalTableFooter.vue";
import InvoiceTaskCost from "@/application/Finance/components/InvoiceTaskCost.vue";
export default {
  data() {
    return {
      displayAddItems: false,
      ceDescription: "",
      ceName: "",
      isLoading: false,
      isApproving: false,
      downloading: false,
      estimateDialog: false,
      validUntil: null,
      terms: "Cash on Delivery",
      termOptions: [
        { label: "Cash on Delivery", value: "COD" },
        { label: "Net 30", value: "NET30" },
        // Add more options as needed
      ],
    };
  },

  beforeMount() {
    state.invoice = null;
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    this.isLoading = true;
    await this.loadData();
    // Pre-populate the edit fields with current values

    console.log(this.stateInvoice?.invoice);

    this.ceDescription = this.stateInvoice?.invoice?.description;
    this.ceName = this.stateInvoice?.invoice?.name;
    // Optionally pre-populate validUntil and terms if available on the invoice

    this.validUntil =
      this.$moment(this.stateInvoice?.invoice?.date_due).format("YYYY-MM-DD") ||
      null;

    this.terms = this.stateInvoice?.invoice?.terms || null;
    this.isLoading = false;
  },
  computed: {
    clientTerms() {
      return state.clientTerms;
    },
    uid() {
      return this.$route.params.estimate_uid;
    },
    jobId() {
      return this.$route.params.uid;
    },
    stateInvoice() {
      return state.invoice;
    },
    subTotal() {
      var s = 0;
      this.stateInvoice?.items.forEach((g) => {
        g.tasks.forEach((t) => {
          s += parseFloat(t.total);
        });
        g.costs.forEach((t) => {
          s += parseFloat(t.total);
        });
      });
      return s;
    },
    jobItems() {
      return state.jobItems;
    },
    job() {
      return state.job;
    },
    typeItems() {
      return this.stateInvoice?.items?.map((item) => {
        const tasksWithType = (item.tasks || []).map((task) => ({
          ...task,
          type: "task",
        }));
        const costsWithType = (item.costs || []).map((cost) => ({
          ...cost,
          type: "cost",
        }));

        return {
          ...item,
          tasks: tasksWithType,
          costs: costsWithType,
        };
      });
    },
  },
  methods: {
    async getPdf(id) {
      this.downloading = true;
      await this.downloadPdf(
        process.env.VUE_APP_ROOT_API + `/v1/finance/download/${id}`
      );
      setTimeout(() => {
        this.downloading = false;
      }, 3000);
    },
    approveEstimate() {
      this.isApproving = true;
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            `/v1/finance/estimate/approve/${this.stateInvoice.invoice.uid}`
        )
        .then(async (response) => {
          this.$toast.add({
            severity: "success",
            summary: `${this.stateInvoice?.invoice?.document_number} has been approved`,
            life: 3000,
          });
          this.$bus.emit("refreshData");
          this.isApproving = false;
        })
        .catch((error) => {
          this.isApproving = false;
          console.error(error);
        });
    },
    formatDate(date) {
      return this.$moment(date).format("DD MMM YY");
    },
    getTagStyle() {
      switch (this.stateInvoice?.invoice?.status) {
        case "draft":
          return "background: #B9C5D0 !important;color: #252631 !important;";
        case "issued":
          return "background: #f59e0b !important;color: #ffffff !important;";
        case "approved":
          return "background: rgb(109, 210, 48)!important;color: #ffffff !important;";
        default:
          return "background: #F8FAFB !important;color: #778CA2 !important;";
      }
    },
    async loadData() {
      await Promise.all([
        fetchInvoice(this.uid),
        fetchJobItems(this.$route.params.uid, this.uid),
      ]);
    },
    closeEstimate() {
      this.$router.push({ name: "job--estimates" });
    },
    openEditDialog() {
      // Pre-populate the edit fields with the current invoice values
      this.ceName = this.stateInvoice?.invoice?.name;
      this.ceDescription = this.stateInvoice?.invoice?.description;

      this.validUntil =
        this.$moment(this.stateInvoice?.invoice?.date_due).format(
          "YYYY-MM-DD"
        ) || null;

      this.terms = this.stateInvoice?.invoice?.terms || null;
      this.estimateDialog = true;
    },
    async saveEdit() {
      try {
        const payload = {
          name: this.ceName,
          description: this.ceDescription,
          valid_until: this.validUntil,
          terms: this.terms,
        };
        await this.$axios.put(
          process.env.VUE_APP_ROOT_API +
            `/v1/finance/estimate/${this.stateInvoice.invoice.uid}`,
          payload
        );
        this.$toast.add({
          severity: "success",
          summary: "Estimate updated",
          life: 3000,
        });
        this.estimateDialog = false;
        this.$bus.emit("refreshData");
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Error updating estimate",
          detail: error.message,
          life: 3000,
        });
      }
    },
  },
  metaInfo() {
    return {
      title: "Estimate",
      meta: [{ vmid: "description", name: "description", content: "" }],
    };
  },
  name: "Estimate",
  components: { TotalTableFooter, InvoiceTaskCost },
};
</script>

<style>
.bluehover:hover {
  color: #4d7cfe !important;
}

/* Styles to control the edit icon visibility on hover */
.parent-div {
  position: relative;
}
.edit-icon {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}
.parent-div:hover .edit-icon {
  display: block;
}
</style>
