<template>
  <td
    :class="'col resource-cell ' + cellClass"
    @mousedown="handleMouseDown"
    @mouseenter="handleMouseEnter"
    @contextmenu="showContextMenu"
  >
    <i
      v-if="isLock"
      class="las la-lock lockIcon"
    />

    <div
      class="flex flex-column text-center height100"
      v-if="value?.includes('|')"
    >
      <div>{{ splitValue[0] }}</div>
      <div class="pt-2px">{{ splitValue[1] }}</div>
    </div>
    <div
      v-else
      class="flex align-items-center justify-content-center"
    >
      <template v-if="!loading">
        <template v-if="value !== 'hg'">
          {{ value }}
        </template>
        <i
          v-else
          class="las la-hourglass-end text-16 text-center whiteIcon"
        />
      </template>
      <PulseLoader
        v-else
        :color="loadingColor"
        size="5px"
      />
    </div>
  </td>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { debounce } from "lodash";

export default {
  name: "TaskScheduleRow",
  components: {
    PulseLoader,
  },
  props: {
    user: Object,
    task: Object,
    index: String,
    i: Object,
    selectedCells: Array,
    isDragging: Boolean,
    today: String,
    lastToggle: Number,
    isTop: Boolean,
    isBottom: Boolean,
    yesterday: String,
    isTimesheetView: Boolean,
    isOverrun: Boolean,
  },
  data() {
    return {
      loadingColor: "#6dd230",
      loading: false,
    };
  },
  computed: {
    value() {
      return this.getCellValue(this.task, this.index);
    },
    splitValue() {
      return this.value?.split("|");
    },
    isWorkDay() {
      return this.user.workingDays?.[this.i.dayAbbr] ?? false;
    },
    isLock() {
      return (
        this.task.resource_plan?.some(
          (plan) => plan.date === this.index && plan.locked === 1
        ) ?? false
      );
    },
    cellClass() {
      return this.getCellClass();
    },
  },
  methods: {
    showContextMenu(event) {
      if (this.$el.classList.contains("cell-sleep")) {
        event.preventDefault();
        return;
      }
      this.$store.dispatch("setSelectedObject", {
        task_uid: this.task.uid,
        date: this.index,
        user_uid: this.user.uid,
        locked: this.isLock,
      });
      this.$emit("toggleCellMenu", event);
    },
    getCellClass() {
      const {
        index,
        task,
        today,
        yesterday,
        isTimesheetView,
        user,
        selectedCells,
      } = this;
      let style = [];

      var isAvailable = false;

      if (index === today) style.push("today");
      if (index === yesterday) style.push("yesterday");
      if (index === task.date_due?.date && !isTimesheetView)
        style.push("cell-due-date");

      const isSelected = selectedCells.some(
        (a) =>
          a.uid === user.uid && a.tid === task.uid && a.date.includes(index)
      );

      if (isSelected && task.resource_plan) {
        const c = task.resource_plan.find((a) => a.date === index);
        style.push(
          "cell-loading",
          isTimesheetView || c ? "available" : "active"
        );
        this.loadingColor = c ? "#6dd230" : "#f2f4f6";
        this.loading = true;
        return style.join(" ");
      }

      this.loading = false;

      if (task.resource_plan) {
        const c = task.resource_plan.find((a) => a.date === index);

        if (c) {
          style.push(
            index < today
              ? isTimesheetView
                ? "cell-sleep cell-blank past-sleep"
                : "past"
              : isTimesheetView
              ? "cell-sleep cell-blank"
              : this.isOverrun && c.time_worked && c.time_worked != "00:00"
              ? "cell-overun-spent"
              : this.isOverrun
              ? "cell-overun"
              : c.time_worked && c.time_worked != "00:00"
              ? "time-spent"
              : "cell-active"
          );
          style.push(c.locked !== 1 ? "cursor-pointer" : "cursor-default");
        } else if (
          index >= task.date_start?.date &&
          task.date_due?.date >= index &&
          this.isWorkDay
        ) {
          isAvailable = true;
          style.push(
            isTimesheetView
              ? "cell-sleep cell-blank"
              : "cell-available cursor-pointer"
          );
        } else {
          style.push(this.isWorkDay ? "cell-blank" : "cell-sleep");
          if (this.isTop)
            style.push("cell-sleep-top-left cell-sleep-top-right");
          if (this.isBottom)
            style.push("cell-sleep-bottom-left cell-sleep-bottom-right");
        }
      }

      if (
        (style.includes("cell-active") && this.value == null) ||
        (!!this.value && isAvailable)
      ) {
        // replace cell-active with cell-loading
        this.loading = true;
        style.push("cell-loading");
      }

      return style.join(" ");
    },
    getCellValue(task, index) {
      const r = task.resource_plan?.find((a) => a.date === index);

      if (r) {
        if (!r.time) return "hg";
        return index < this.today
          ? `${this.secToHM(r.minutes_worked * 60)}|${r.time}`
          : r.time_worked && r.time_worked != "00:00"
          ? `${r.time_worked}|${r.time}`
          : r.time;
      }
      return this.isWorkDay ? null : "X";
    },

    handleMouseDown(event) {
      if (
        this.$el.classList.contains("cell-blank") ||
        this.$el.classList.contains("time-spent") ||
        !this.isWorkDay ||
        this.$el.classList.contains("cursor-default")
      )
        return;
      if (event.which === 3) return;
      if (this.index < this.today) return;

      this.$emit("isDragging");
      this.$emit("lastToggle", this.index);

      if (
        this.isOverrun &&
        !this.$el.classList.contains("cell-active") &&
        !this.$el.classList.contains("cell-overun")
      ) {
        this.$store.dispatch("setSelectedObject", {
          task_uid: this.task.uid,
          date: this.index,
          user_uid: this.user.uid,
        });
        this.$emit("showConfirmDialog");
        return;
      }

      const existingCellIndex = this.selectedCells.findIndex(
        (a) =>
          a.uid === this.user.uid &&
          a.tid === this.task.uid &&
          a.date.includes(this.index)
      );
      if (existingCellIndex === -1) {
        this.selectedCells.push({
          uid: this.user.uid,
          tid: this.task.uid,
          date: [this.index],
          overrun: this.isOverrun,
        });
      }
    },
    handleMouseEnter: debounce(function () {
      if (
        this.index < this.today ||
        !this.isWorkDay ||
        this.index > this.task.date_due?.date
      )
        return;
      if (this.isDragging && this.index !== this.lastToggle) {
        const cell = this.selectedCells.find(
          (a) => a.uid === this.user.uid && a.tid === this.task.uid
        );
        if (!cell) {
          return;
        }
        if (!cell.date.includes(this.index)) cell.date.push(this.index);

        let min = Math.min(...cell.date.map((d) => new Date(d)));
        let max = Math.max(...cell.date.map((d) => new Date(d)));

        for (
          let d = new Date(min);
          d <= new Date(max);
          d.setDate(d.getDate() + 1)
        ) {
          const shortDate = this.buildShortDate(d);
          if (
            !cell.date.includes(shortDate) &&
            this.$parent.checkWorkDay(shortDate)
          ) {
            cell.date.push(shortDate);
          }
        }

        cell.date = cell.date.filter(
          (d) => new Date(d) >= min && new Date(d) <= max
        );
      }
    }, 100),
  },
};
</script>

<style scoped>
</style>
