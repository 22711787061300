
import store from "@/store";
import { refreshData, state } from '../services/data_service.js'
// import { fetchTaskTimes } from "../../../services/data_service.js";
const { FormatMoney } = require('format-money-js');
const numberFormat = Intl.NumberFormat('en-US');
export const GlobalMixin = {
  data() {
    return {
      permissionEnums: [{
        name: 'Full',
        id: 'F'
      }, {
        name: 'View',
        id: 'V'
      }, {
        name: 'New',
        id: 'N'
      }, {
        name: 'Edit',
        id: 'E'
      }, {
        name: 'Delete',
        id: 'D'
      }, {
        name: 'Print',
        id: 'P'
      }]
    }
  },
  methods: {
    deletePrompt(title, url, type = 'delete') {
      return new Promise((resolve, reject) => {
        this.$confirm.require({
          message: "Are you sure you want to delete " + title + "?",
          header: "Confirm Delete",
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            this.$axios[type](process.env.VUE_APP_ROOT_API + url, {})
              .then((response) => {
                this.$bus.emit("refreshData");
                this.$toast.add({
                  severity: "success",
                  summary: title + " Deleted",
                  detail: title + " has been deleted",
                  life: 3000,
                });
                resolve(true); 
              })
              .catch((error) => {
                console.error(error);
                reject(false); 
              });
          },
          reject: () => {
            reject(false);
          },
        });
      });
    },    
    buildShortDate(d) {
      var sD =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1) +
        "-" +
        (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

      return sD;
    },
    arrayRemove(arr, value) {
      return arr.filter((ele) => {
        return ele != value;
      });
    },
    clearSliderContent() {
      store.dispatch('setSliderView', '')
      store.dispatch('setSelectedObject', null)
    },
    clearPopupDialog() {
      store.dispatch('setPopupDialog', '')
    },
    hideRocketLoader() {
      store.dispatch('rocketLoader', false)
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    secTohMS(time) {
      var hrs = ~~(time / 3600);
      var mins = ~~((time % 3600) / 60);
      var secs = ~~time % 60;
      var ret = "";
      ret += "" + (hrs < 10 ? (hrs < 1 ? "" : "0") : "")

      ret += "" + hrs < 1 ? "" : hrs + ":" + ":" + (mins < 10 ? "0" : "");
      ret += "" + mins + ":" + (secs < 10 ? "0" : "");
      ret += "" + secs;
      return ret;
    },
    timeToSeconds(time) {
      var arr = time.split(':')
      var seconds = parseInt(arr[0]) * 60 * 60
      seconds = seconds + parseInt(arr[1]) * 60
      return seconds;
    },
    secToHMS(time) {
      var hrs = ~~(time / 3600);
      var mins = ~~((time % 3600) / 60);
      var secs = ~~time % 60;
      var ret = "";
      ret += "" + (hrs < 10 ? "0" : "")
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
      ret += "" + mins + ":" + (secs < 10 ? "0" : "");
      ret += "" + secs;
      return ret;
    },
    secToHM(time) {
      var hrs = ~~(time / 3600);
      var mins = ~~((time % 3600) / 60);
      var secs = ~~time % 60;
      var ret = "";
      ret += "" + (hrs < 10 ? "0" : "")
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
      ret += "" + mins;
      return ret;
    },
    updateInputSwitch(v) {
      if (v === '1') {
        return '0'
      } else {
        return '1'
      }
    },
    get_today_work_hours(task, user_uid) {
      {
        if (!task) {
          return null
        }
        var today = new Date().toDateString().slice(3, 15);
        var min = 0;

        var daily_hours = "08:00";
        for (let index = 0; index < task.users.length; index++) {
          const element = task.users[index];

          if (element.uid === user_uid) {
            daily_hours = this.secToHMS(element.minutes_planned * 60).slice(3, 8);
            for (
              let index3 = 0;
              index3 < element.resource_plan.length;
              index3++
            ) {
              const r = element.resource_plan[index3];
              if (new Date(r.date).toDateString().slice(3, 15) == today) {
                min += parseFloat(r.minutes);
              }
            }
          }
        }
        if (min > 0) {
          return this.secToHMS(min).slice(3, 8);
        }

        return daily_hours;
      }
    },

    formatCurrency(value, currency, maxDecimals = 2) {
      value = parseFloat(value);
      if (currency == undefined) {
        currency = state?.accounts?.accountFinance?.currency?.description ?? "R"
      }


      const fm = new FormatMoney({
        decimals: maxDecimals
      });


      // if value is undefined or null or NaN, then value is 0
      if (value === undefined || value === null || isNaN(value)) {
        value = 0;
      }
    
      var t = fm.from(value, { symbol: currency })



      if (maxDecimals > 0) {
        var s = t.split('.')
        var dec = s[1]
        for (let index = dec.length - 1; index >= 2; index--) {
          if (dec[index] == 0) {
            dec = dec.slice(0, -1)
          } else {
            break;
          }
        }
        t = s[0] + '.' + dec
      }

      return t

    },
    formatAmount(value) {
      return Number(value).toFixed(2);
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },
    convertH2M(timeInHour) {
      var timeParts = timeInHour.split(":");
      return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    },
    convertMinsToHrsMins(minutes) {
      var h = Math.floor(minutes / 60);
      var m = Math.round(minutes % 60); // Round before formatting    
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;    
      return h + ":" + m;
    },
     isObject(value) {
      return typeof value === 'object' && value !== null;
    },    
    getIntials(user) {
      if(!this.isObject(user)){
        return
      }
      return user?.name
        ? user?.name[0]
        : "" + user?.surname
          ? user?.surname[0]
          : "";
    },
    getColorFromName(user) {
      var hash = 0;
      if (!user) {
        return '#000000'
      }
      var str =
        user.name +
        user.surname +
        user.username +
        user.email;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var h = hash % 6;
      if (h < 0) {
        h = h * -1;
      }

      var cols = [
        "#5E00CC",
        "#00BAFF",
        "#F5D900",
        "#00B978",
        "#E0005E",
        "#ED5200",
        "#CCB244",
      ];


      return cols[h];
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    downloadPdf(url) {
      this.$axios.get(url)
        .then((response) => {
          window.open(response.data.data, '_blank');

        })
        .catch((error) => {
          console.error(error);
        });
    },
    canView(permission) {
      var role = 'user'
      if(store.getters.permissions == 1){
          role = 'admin'
      }
      return permission == role
    },
    reverseCurrencyMap(currency) {
      var map = {
        'R': 'ZAR',
        '$': 'USD',
        '€': 'EUR',
        '£': 'GBP',
        '¥': 'JPY',
        '₹': 'INR',
        '₽': 'RUB',
        '₩': 'KRW',
        '₿': 'BTC',
        '฿': 'THB',
        '₺': 'TRY',
        '₴': 'UAH',
        '₮': 'MNT',
        '₦': 'NGN',
        '₲': 'PYG',
        '₡': 'CRC',
        '₵': 'GHS',
        '₪': 'ILS',
        '₫': 'VND',
        '₭': 'LAK',
        '₯': 'GRD',
        '₰': 'PF'
      }
      return map[currency]
    }
  }
}