import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import routes from './routes'

Vue.use(VueRouter)

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

  const router = new VueRouter({
    scrollBehavior: () => ({
      x: 0,
      y: 0
    }),
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

  router.beforeEach((to, from, next) => {

    // check if any div with class p-column-filter-overlay exist, if so delete it
    if (document.querySelector('.p-column-filter-overlay')) {
      document.querySelector('.p-column-filter-overlay').remove();
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.getters.isAuthenticated) {
        // store route to redirect after login
        localStorage.setItem('redirectUrl', to.fullPath);
        next({
          path: '/login'
        })
      } else {
        next()
      }
    } else {
      next() // make sure to always call next()!
    }
  })

  export default router

