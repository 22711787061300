<template>
  <div class="card col-12 pt-3">
    <!-- Filters Section -->
    <div class="flex flex-wrap justify-content-between align-items-center mb-3">
      <div class="flex">
        <!-- Dropdown Filters -->
        <Dropdown
          class="mr-3"
          :options="accountManagers"
          style="width: 220px;"
          optionLabel="label"
          optionValue="value"
          v-model="selectedAccountManager"
          placeholder="Account Manager"
          v-if="!isLoading"
        />
        <Skeleton
          v-if="isLoading"
          height="40px"
          width="220px"
        />

      </div>

      <div class="flex">
        <span class="p-input-icon-right">
          <InputText
            class="mr-3"
            style="width: 100%;"
            v-model="search"
            placeholder="Search"
            v-if="!isLoading"
          >
          </InputText><i
            class="las la-times cursor-pointer mr-2"
            style="right:0;color:#6c757d"
            @click="search = ''"
            v-if="search"
          ></i>
        </span>
        <Skeleton
          v-if="isLoading"
          height="40px"
          width="220px"
        />

      </div>

    </div>

    <DataTable
      :value="filteredJobs"
      rowGroupMode="subheader"
      groupRowsBy="client.name"
      responsiveLayout="scroll"
      sortMode="single"
      sortField="client.name"
      :rowClass="rowClass"
      :sortOrder="1"
      @row-click="navJob"
      :filters.sync="filters"
      dataKey="uid"
      :rows="30"
      :rowsPerPageOptions="[30, 50, 100]"
      paginator
    >
      <template #empty>
        <div class="flex justify-content-center sm:h-20rem align-items-center sm:p-6 text-16">
          <div style="
          display: inline-block;
          padding: 30px 80px;
          background: #f8fafb;
          border-radius: 100px;
        ">{{ isLoading ? 'Loading...' : 'No data to display ' }}
          </div>
        </div>
      </template>

      <!-- Group Header Template -->
      <template #groupheader="slotProps">
        <div class="font-bold pt-3 pb-2 sticky-header">
          {{ slotProps.data.client_code }} <span v-if="slotProps.data.client_code && slotProps.data.client.name">|</span> {{ slotProps.data.client.name }}
        </div>
      </template>

      <!-- Table Columns -->
      <Column
        field="job_number"
        sortable
        header="No"
        :styles="{ whiteSpace: 'nowrap' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="75%"
          ></Skeleton>
          <template v-else>
            <div class="font-light-hover cursor-pointer">
              {{ slotProps.data?.job_number }}
            </div>
          </template>
        </template>
      </Column>

      <Column
        field="title"
        sortable
        header="Name"
        :styles="{ whiteSpace: 'nowrap' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="66%"
          ></Skeleton>
          <template v-else>
            <div
              class="cursor-pointer"
              style="max-width: 300px; overflow-x: clip; text-overflow: ellipsis; white-space: nowrap;"
            >
              <span :class="slotProps.data.entryType == 'job' ? 'font-bold' :''"> {{ slotProps.data.title }}</span>
            </div>
          </template>
        </template>
      </Column>

      <Column
        field="managed_by.name"
        sortable
        header="AE"
        :styles="{ whiteSpace: 'nowrap' }"
      >
        <template #filter="{ filterModel }">
          <InputText
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Search by name"
          />
        </template>
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else>
            {{ slotProps.data.managed_by.name }}
          </template>
        </template>
      </Column>

      <Column
        field="status"
        sortable
        header="Status"
        :styles="{ whiteSpace: 'nowrap' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else-if="slotProps.data.status">
            <div
              class="text-12"
              style="width: 30px"
            >
              <span
                class="p-2"
                :style="
            slotProps.data.status === 'active' || slotProps.data.status === 'issued'|| slotProps.data.status === 'approved'
              ? 'background: #F4FBEF; color: #6dd230;  border-radius: 6px;'
              : 'background: #E8ECEF; border-radius: 6px;'
          "
              >
                {{ toTitleCase(slotProps.data.status) }}
              </span>
            </div>
          </template>
        </template>
      </Column>

      <Column
        field="date_created"
        header="Created"
        dataType="date"
        :styles="{ whiteSpace: 'nowrap' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else-if="slotProps.data.created_date?.date">
            {{ slotProps.data.created_date.date | moment("DD MMM YYYY") }}
          </template>
        </template>
      </Column>

      <Column
        field="metrics.time"
        header="Time"
        :styles="{ whiteSpace: 'nowrap' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <div
            v-else-if="slotProps.data.metrics?.time?.tracked && slotProps.data.metrics.time.estimated"
            style="height:27px"
          >

            <div
              class="text-md"
              v-if="slotProps.data.metrics?.time?.tracked"
            >
              {{
            slotProps.data.metrics?.time?.tracked
              ? slotProps.data.metrics.time.tracked
              : "00:00"
          }}
              /
              {{
            slotProps.data.metrics?.time?.estimated
              ? slotProps.data.metrics.time.estimated
              : "00:00"
          }}
            </div>
            <div
              class="flex align-items-center mt-1"
              v-if="slotProps.data.metrics?.time?.tracked"
            >
              <div
                class="surface-300 border-round overflow-hidden w-full"
                style="height: 6px"
              >
                <div
                  class="h-full"
                  :style="getProgressStyle(slotProps.data)"
                />
              </div>
            </div>
          </div>
        </template>
      </Column>

      <Column
        field="amount"
        header="Total Incl."
        :styles="{ whiteSpace: 'nowrap' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else>
            <span :class="slotProps.data.entryType == 'job' ? 'font-bold' :slotProps.data.entryType == 'ce' ? 'font-light' :''  ">{{ formatCurrency(slotProps.data.amount) }}</span>
          </template>
        </template>
      </Column>
    </DataTable>

  </div>
</template>

<script>
import { fetchJobReports, state } from "../../../services/data_service";
export default {
  name: "ActiveJobs",
  data() {
    return {
      selectedAccountManager: "all",
      search: "",
      selectedIncludeOptions: [],
      isLoading: true,
      groupByClient: false,
      filters: {
        global: { value: null, matchMode: "contains" },
      },
      jobs: [], // Jobs data
      accountManagers: [],
      includeOptions: [
        { label: "Cost Estimates - Draft", value: "cost_estimates_draft" },
        { label: "Cost Estimates - Issued", value: "cost_estimates_issued" },
        { label: "Invoices - Draft", value: "invoices_draft" },
        { label: "Invoices - Issued", value: "invoices_issued" },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    this.isLoading = false; // Simulate data being loaded
  },
  computed: {
    jobReports() {
      return state.jobReports;
    },
    filteredJobs() {
      return this.jobs.filter((job) => {
        // First check account manager filter
        if (this.selectedAccountManager !== "all") {
          if (job.managed_by_filter !== this.selectedAccountManager) {
            return false;
          }
        }

        // Then check search term if exists
        if (this.search) {
          const searchTerm = this.search.toLowerCase();
          return (
            job.client?.name?.toLowerCase().includes(searchTerm) ||
            job.title?.toLowerCase().includes(searchTerm) ||
            job.job_number?.toLowerCase().includes(searchTerm) ||
            job.managed_by?.name?.toLowerCase().includes(searchTerm) ||
            job.client_code?.toLowerCase().includes(searchTerm)
          );
        }

        return true;
      });
    },
  },
  methods: {
    navJob(ev) {
      if (ev.data.isJob) {
        const route = "/job/" + ev.data?.uid;
        if (ev.originalEvent.ctrlKey || ev.originalEvent.metaKey) {
          // Ctrl key (or Cmd key on Mac) is pressed, open in new tab
          const url = this.$router.resolve(route).href;
          window.open(url, "_blank");
        } else {
          // Normal click, navigate in the same tab
          // this.$store.dispatch("setSelectedObject", ev.data);
          this.$router.push(route);
        }
      }
      if (ev.data.entryType === "ce" || ev.data.entryType === "inv") {
        const route = "/job/" + ev.data?.job_uid + "/financial/" + ev.data?.uid;
        if (ev.originalEvent.ctrlKey || ev.originalEvent.metaKey) {
          // Ctrl key (or Cmd key on Mac) is pressed, open in new tab
          const url = this.$router.resolve(route).href;
          window.open(url, "_blank");
        } else {
          this.$router.push(route);
        }
      }
    },
    async loadData() {
      try {
        await fetchJobReports();
        this.jobs = this.processJobReports(this.jobReports);
        this.accountManagers = this.extractAccountManagers(this.jobs);
        this.isLoading = false;
      } catch (error) {
        console.error("Error loading data:", error);
        this.isLoading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to load data.",
          life: 5000,
        });
      }
    },
    processJobReports(jobReports) {
      const clients = this.groupJobsByClient(jobReports);
      const sortedClients = this.sortClientsByName(clients);
      var flattenClientDataIntoJobsArray =
        this.flattenClientDataIntoJobsArray(sortedClients);

      return flattenClientDataIntoJobsArray;
    },
    groupJobsByClient(jobReports) {
      const clients = [];
      for (const j in jobReports) {
        const jobReport = jobReports[j];
        let existingClient = clients.find(
          (client) => client.uid === jobReport.client.uid
        );
        if (!existingClient) {
          jobReport.client.jobs = jobReport.jobs;
          clients.push(jobReport.client);
        } else {
          existingClient.jobs = existingClient.jobs.concat(jobReport.jobs);
        }
      }
      return clients;
    },
    sortClientsByName(clients) {
      return clients.sort((a, b) => a.name.localeCompare(b.name));
    },
    flattenClientDataIntoJobsArray(clients) {
      let jobs = [];
      clients.forEach((client) => {
        var c = 0;
        client.jobs.forEach((job) => {
          job.isJob = true;
          job.isFirstJob = c === 0;
          c++;
          job.entryType = "job"; // Mark as Job entry
          jobs.push(job);
          job.managed_by_filter = job.managed_by?.name;

          let ces = [];
          let invs = [];

          var job_total = 0;

          job.finances?.forEach((f) => {
            f.job_uid = job.uid;
            f.isJob = false;
            f.client = client;
            job_total += parseFloat(f.amount);
            f.managed_by_filter = job.managed_by?.name;
            f.job_number = f.document_number ?? "Draft";
            f.title = f.name ?? "N/A";
            f.created_date = { date: f.date_created ?? "N/A" };
            f.managed_by = { name: "" };
            f.status = f.status ?? "draft";
            if (f.transaction_type === "ce") {
              f.entryType = "ce"; // Mark as Cost Estimate
              ces.push(f);
            } else if (f.transaction_type === "inv") {
              f.entryType = "inv"; // Mark as Invoice
              invs.push(f);
            } else {
              f.entryType = "finance"; // Default finance type if needed
              jobs.push(f); // if other finance type, just add after job for now
            }
          });

          job.amount = job.budget.estimated ?? job_total;

          if (ces.length > 0 && invs.length > 0) {
            var lastCe = ces[ces.length - 1];
            lastCe.isLastCe = true;
          }

          if (ces.length > 0 && invs.length == 0) {
            var lastC = ces[ces.length - 1];
            lastC.isLastItem = true;
          }

          if (invs.length > 0) {
            var lastI = invs[invs.length - 1];
            lastI.isLastItem = true;
          }

          // Add CEs and INVs after the job, maintaining CE then INV order
          jobs.push(...ces);
          jobs.push(...invs);
        });
      });

      jobs.forEach((j) => {
        if (!j.client_code) {
          const clientJob = jobs.find((x) => x.client_uid == j.client.uid);
          if (clientJob) {
            j.client_code = clientJob.client_code;
          }
        }
      });

      return jobs;
    },
    extractAccountManagers(jobs) {
      let accountManagers = [];

      accountManagers.push({
        label: "All Account Managers",
        value: "all",
      });

      jobs.forEach((a) => {
        accountManagers.push({
          label: a.managed_by_filter,
          value: a.managed_by_filter,
        });
      });
      return accountManagers.filter(
        (v, i, a) => a.findIndex((t) => t.value === v.value) === i
      );
    },
    toTitleCase(str) {
      if (!str) return "";
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    formatCurrency(number) {
      if (isNaN(number)) return "N/A";
      return new Intl.NumberFormat("en-ZA", {
        style: "currency",
        currency: "ZAR",
      }).format(number);
    },
    timeToSeconds(timeString) {
      if (!timeString) return 0;
      const parts = timeString.split(":");
      if (parts.length !== 2) return 0;
      const hours = parseInt(parts[0], 10) || 0;
      const minutes = parseInt(parts[1], 10) || 0;
      return hours * 3600 + minutes * 60;
    },
    getProgressStyle(task) {
      var tracked = this.timeToSeconds(task.metrics.time.tracked);
      const progress =
        (tracked / (task.metrics.time.estimated_minutes * 60)) * 100;

      if (progress >= 100) {
        return "background: #fe4d97; width: 100%;";
      } else if (progress >= 60) {
        return `background: #ffab2b; width: ${progress.toFixed(2)}%;`;
      } else {
        return `background: #6dd230; width: ${progress.toFixed(2)}%;`;
      }
    },
    rowClass(data) {
      var style = "row-accessories row-accessories2 height-50 ";
      if (data.isJob) {
        style += "grey-background-custom ";

        if (!data.isFirstJob) {
          style += "job-border-top ";
        }
      }

      if (data.isLastCe) {
        style += "ce-border-bottom ";
      }

      if (data.isLastItem) {
        style += "last-i-border-bottom ";
      }

      return style;
    },
  },
};
</script>


<style >
.height-50 {
  height: 50px !important;
}

.job-border-top {
  border-top: 15px solid #ffffff;
}

.ce-border-bottom {
  border-bottom: 10px solid #f2f4f6;
}

.last-i-border-bottom {
  border-bottom: 1px solid #dee2e6;
}
</style>