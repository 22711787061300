<template>
  <div
    class="card overflow-x-hidden p-0"
    ref="card"
    :class="getClass(task.timer_on) + '  cursor-grab'"
  >
    <div class="p-fluid grid">
      <div class="col-12 flex pb-0">
        <div class="flex flex-column flex-grow-1 p-2 justify-content-center align-items-start">
          <transition-expand>
            <div class="font-light">
              <span
                class="cursor-pointer"
                @click="showTask()"
                v-tooltip.bottom="{
                  value: task.job?.title,
                }"
              >
                {{ task.client_name }}</span>
            </div>
          </transition-expand>
          <div
            class="text-link"
            v-if="task.is_complete == 0"
            :style="!expand ? 'margin-top:4px' : ''"
          >
            <span
              class="cursor-pointer"
              @click="showTask()"
            >{{
              setTaskCardTitle()
            }}</span>
          </div>
          <div
            class="text-link"
            v-if="task.is_complete == 1"
            :style="!expand ? 'margin-top:4px' : ''"
          >
            <span
              class="flex align-items-center"
              @click="showTask()"
            >
              <span class="cursor-pointer completed-task-strike-text">{{
                setTaskCardTitle()
              }}</span></span>
          </div>
          <div class="col-12 pl-0 pt-0">
            <div class="flex flex-wrap">
              <template v-if="task && task.tags">
                <div
                  @click.stop="expandTags()"
                  v-for="t in task.tags.filter((a) => a.name)"
                  :key="t.uid"
                >
                  <Chip
                    :style="getStyle(t.colour)"
                    class="tag-chip cursor-pointer"
                  >
                    <div
                      :class="
                        'flex text-sm font-bold tag-chip-not-expand ' +
                        (tags_expanded === true ? 'tag-chip-expand' : '')
                      "
                      style="margin-top: 1px"
                    >
                      {{ t.name }}
                    </div>
                  </Chip>
                </div>
              </template>
            </div>
          </div>
          <transition-expand>
            <!-- <div
              class="text-sm text-12 font-light pre-formatted"
              v-if="expand == true"
              v-html="task.description"
            ></div -->
            <div
              class="field col-12 mb-0"
              v-if="expand == true"
            >
              <div
                v-html="task.description"
                class="mb-3"
              ></div>
            </div>
            >
          </transition-expand>
        </div>
        <div class="flex flex-none align-items-start p-2">
          <div
            class="flex justify-content-end align-items-center"
            v-if="listId === 2"
          >
            <i class="las la-flag pr-2 text-link cursor-pointer pt-1" />
            <p class="text-link pr-2 pt-1">
              {{ formatHM(today_work_hours) }}
            </p>
          </div>
          <template v-if="task.is_complete == 0 && task.in_review == 0">
            <Button
              @click="addToDesk(task.uid)"
              icon="las la-stopwatch i-20"
              style="height: 26px; width: 26px"
              :class="
                'p-button-rounded  text-sm  ' + getTimerClass(task.timer_on)
              "
              v-if="!isSwitching"
            />

            <ProgressSpinner
              class="text-sm"
              style="
                width: 18px;
                height: 18px;
                margin-top: 4px;
                margin-left: 8px;
                margin-bottom: 4px;
              "
              v-else
            />
          </template>
          <div v-if="task.is_complete == 1">
            <i
              v-if="!isSwitching"
              class="las la-check-circle i-20 grey task-icon-complete"
            />
            <ProgressSpinner
              class="text-sm"
              style="width: 18px; height: 18px"
              v-else
            />
          </div>
          <div v-if="task.in_review == 1">
            <i
              v-if="!isSwitching"
              class="las la-user-clock i-20 grey task-icon-in-review text-sm"
            />
            <ProgressSpinner
              class="text-sm"
              style="width: 18px; height: 18px"
              v-else
            />
          </div>
          <i
            class="las la-ellipsis-v text-20 cursor-pointer"
            @click="toggleMenu"
          />
        </div>
      </div>

      <div class="col-12 flex border-top align-items-cente justify-content-between pr-3 pt-1 pb-1 pl-3">
        <div class="flex align-items-center text-sm font-light">
          <i class="i-14 la la-calendar mr-1" />{{ task.date_due?.nice }}
          <div
            :style="getTagStyle() + 'borderRadius: 6px'"
            class="p-2 task-tag ml-3"
          >
            {{
              task.is_approved == 0
                ? "Draft"
                : task.in_review == 1
                ? "In Review"
                : task.is_complete == 1
                ? "Complete"
                : task.date_due?.time_until === "Overdue"
                ? "Overdue"
                : "In Progress"
            }}
          </div>
        </div>
        <div class="flex align-items-center font-light">
          <i class="i-14 la la-stopwatch pb-1 pr-1" />
          <div class="text-center text-sm">
            {{  convertMinsToHrsMins(getUsedTime(task)) }} /
            {{ getAllocatedHours(task) }}
          </div>
        </div>
      </div>
    </div>
    <div
      :style="getProgressStyle(task)"
      class="task-entry-progress"
    />
  </div>
</template>
<script>
import { state } from "../../../services/data_service.js";
import { temp_state } from "../../../services/temp_state_service.js";
export default {
  name: "TaskCard",
  data() {
    return {
      showTag: null,
      taskTagUID: null,
      isSwitching: false,
      cardWidth: null,
      windowHeight: window.innerHeight,
    };
  },
  props: {
    task: Object,
    expand: Boolean,
    listId: Number,
  },
  watch: {
    "task.timer_on"() {
      this.isSwitching = false;
    },
  },
  computed: {
    currentTimer() {
      return this.$store.getters.currentTimer;
    },
    isPausedTask() {
      return state.pausedTasks.filter((a) => a.uid === this.task.uid);
    },
    today_work_hours() {
      return this.get_today_work_hours(this.task, this.user_uid);
    },
    user_uid() {
      return this.$store.getters.user?.uid;
    },
    tags_expanded() {
      return temp_state.tagsExpanded;
    },
  },
  methods: {
    getUsedTime(task) {
      return task.users.filter((u) => u.uid === this.user_uid)[0].minutes;
    },
    setTaskCardTitle() {
      if (this.task.title == "" || this.task.title == null) {
        return this.task.task_type_name;
      }
      return this.task.task_type_name + " - " + this.task.title;
    },
    getStyle(c) {
      if (c && c.charAt(0) !== "#") {
        c = "#" + c;
      }
      return "background-color: " + c + ";color:white;";
    },
    expandTags() {
      temp_state.tagsExpanded = !temp_state.tagsExpanded;
    },
    getTagStyle() {
      if (this.task.is_approved == 0) {
        return "text-align: center; rgb(232, 236, 239) !important; padding: 6px";
      }
      if (
        this.task.date_due?.time_until === "Overdue" &&
        this.task.is_complete == 0 &&
        this.task.in_review == 0
      ) {
        return "text-align: center; background: #FFF1F7 !important; color: #FE4D97 !important; padding: 6px";
      } else if (this.task.in_review == 1) {
        return "text-align: center; background: #fff2de !important;color:#FFAB2B !important; padding: 6px";
      }
      return "text-align: center; background: #f4fbef !important;color: #6dd230 !important; padding: 6px";
    },
    showTask() {
      this.$store.dispatch("setSelectedObject", { uid: this.task.uid });
      this.$store.dispatch("setSliderView", "TaskSlider");
    },
    getClass(t) {
      return t == 1 ? "taskActive" : "";
    },
    getTimerClass(t) {
      return t == 1 ? "timerActive" : "grey-button";
    },
    toggleMenu(event) {
      this.$emit("toggleMenu", event);
    },
    calcPausedTime() {
      var t = 0;
      this.isPausedTask.forEach((a) => {
        t = t + parseInt(a.time_spent);
      });
      t = t + parseInt(this.task.seconds ? this.task.seconds : 0);
      return this.secToHM(t);
    },
    addToDesk(uid) {
      this.isSwitching = true;
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API + "/v1/tasks/" + uid + "/timer/toggle",
          {}
        )
        .then((response) => {
          this.$bus.emit("refreshData");
          this.$store.dispatch("setTimer", response.data.data);
          this.isLoading = false;
          this.users = response.data.data;
          setTimeout(() => {
            this.isSwitching = false;
          }, 1500);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDynamicStyle() {
      if (this.tcWidth < 300) {
        return "justify-content-center";
      } else {
        return "justify-content-between";
      }
    },
    getProgressStyle(task) {
      var user = task.users.find((a) => a.uid === this.user_uid);
      if (!user) return;
      var progress = user.progress_bar ? user.progress_bar : 0;
      if (progress >= 100) {
        return "background:#FE4D97; border-bottom-left-radius: 12px;border-bottom-right-radius: 12px;";
      } else {
        return (
          "border-bottom-left-radius: 12px; background: #6DD230; width:" +
          progress +
          "%"
        );
      }
    },
    formatHM(time) {
      return time.replace(":", "h");
    },
    getAllocatedHours(task) {
      var user = task.users.find((a) => a.uid === this.user_uid);
      if (user && user.estimated_hours) {
        return user.estimated_hours;
      }
      return task.estimated_hours;
    },
  },
};
</script>
<style>
.days-tag {
  text-align: center;
  color: #6dd230 !important;
  background-color: #f3fcee !important;
  padding: 6px !important;
}

.task-tag {
  font-size: 12px;
  border-radius: 4px;
}

.due-date-tag {
  color: #778ca2;
}

.task-entry-progress {
  height: 4px;
}

.timerActive {
  background-color: #6dd230 !important;
  border-color: #6dd230 !important;
  color: white !important;
}
</style>