<template>
  <tbody v-if="user  || search?.length > 0">
    <tr
      v-if="forToday"
      slot="header"
      class="resource-thead-user"
      id="userRow"
    >
      <th class="header user-header resource-th">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText
            class="flex schedule-search"
            clearable
            style="width: 275px;"
            v-model="search"
            @input="updateSearch(search)"
            placeholder="Search"
            icon="pi pi-search"
          />
          <i
            class="las la-times cursor-pointer mr-2"
            style="right:0;color:#6c757d"
            @click="search = '';updateSearch(search)"
            v-if="search"
          ></i>
        </span>
      </th>
      <TaskScheduleProgress
        v-for="
            (i,
            index)
            in
            days"
        :key="index"
        :index="index"
        :user="user"
        :today="today"
        :dayAbbr="i.dayAbbr"
      />
    </tr>
    <tr
      style="background-color: #f8fafb"
      v-if="forToday"
    >
      <th
        class="flex header task-header pr-2 cursor-pointer"
        style="background-color: #f8fafb"
      >
        <div class="col-9 flex vertical-middle p-02">
          <div class="flex font-weight-normal pt-1">
            <span>For Today</span>
          </div>
        </div>
      </th>
      <td
        v-for="(i, index) in days"
        :key="index + '-up'"
        :style="
            index == today
              ? 'border-left-style: solid; border-left-color: #4D7CFE;border-left-width:0.15rem'
              : ''
          "
      />
    </tr>
    <tr
      v-for="task in tasks"
      :key="task.uid"
    >
      <th
        class="flex header task-header pr-2 background-unplanned my-task-header"
        style="width:300px"
      >
        <div class="col p-02 justify-content-start align-self-center flex-column">
          <div
            @click="getTask(task)"
            class="font-weight-normal text-start text-ellipsis-wrap cursor-pointer"
          >
            <span class="font-light font-smaller">
              {{ task.job?.client }}
            </span>
            <br>
            <span
              class="task-title"
              :title="task.title ? task.title : task.task_type_name"
            >
              {{ task.title ? task.title : task.task_type_name }}
            </span>
          </div>
        </div>
        <div class="col-grow-0 inline-flex align-self-center justify-content-between">

          <ProgressSpinner
            class="text-sm"
            style="
                width: 18px;
                height: 18px;
                margin-top: 4px;
                margin-left: 8px;
                margin-bottom: 4px;
              "
            v-if="isSwitching == task.uid"
          />

          <Button
            icon="las la-stopwatch i-20"
            style="height: 26px; width: 26px"
            :class="'p-button-rounded  text-sm ' + getTimerClass(task)"
            @click="addToDesk(task.uid)"
          />

        </div>
      </th>
      <td
        v-for="(i, index) in days"
        :key="task.uid + '-' + index"
        :class="'col resource-cell ' + getCellClass(index, task, today)"
      >
        <MyScheduleCell
          :task="task"
          :index="index"
          :prevDay="prevDay(index)"
          :nextDay="nextDay(index)"
          :today="today"
          :days="days"
          :isWorkDay="isWorkDay(index)"
          :tasks="tasks"
        />
      </td>
    </tr>

  </tbody>
</template>
  
  <script>
import { state } from "../../../services/data_service";
import TaskScheduleProgress from "../../TaskSchedule/components/TaskScheduleProgress.vue"; // Adjust path if needed
import MyScheduleCell from "./MyScheduleCell.vue"; // Adjust path if needed

export default {
  components: {
    TaskScheduleProgress,
    MyScheduleCell,
  },
  computed: {
    timer() {
      return this.$store.getters.timer;
    },
    myDesk() {
      return state.myDesk;
    },
  },
  props: {
    forToday: {
      type: Boolean,
    },
    tasks: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    days: {
      type: Object,
      required: true,
    },
    today: {
      type: String,
      required: true,
    },

    getCellClass: {
      // Pass the function as a prop
      type: Function,
      required: true,
    },
    prevDay: {
      type: Function,
      required: true,
    },
    nextDay: {
      type: Function,
      required: true,
    },
    isWorkDay: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      isSwitching: false,
    };
  },
  methods: {
    updateSearch(value) {
      this.$emit("update:search", value);
    },
    getTask(task) {
      this.$store.dispatch("setSelectedObject", task.uid);
      this.$store.dispatch("setSliderView", "TaskSlider");
    },
    getTimerClass(task) {
      if (task.uid == this.myDesk?.uid) {
        if (this.timer?.timer_end == 0) {
          return "timerActive";
        }
        if (this.timer) {
          return "grey-button";
        }
      }
      return "stopwatchButtonHover grey-button";
    },
    addToDesk(uid) {
      this.isSwitching = uid;
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API + "/v1/tasks/" + uid + "/timer/toggle",
          {}
        )
        .then((response) => {
          this.$bus.emit("refreshData");
          this.$store.dispatch("setTimer", response.data.data);
          setTimeout(() => {
            this.isSwitching = null;
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
  
  <style scoped>
/* Add scoped styles here if needed */
.schedule-search {
  border: 0px !important;
  background-color: #f2f4f6 !important;
}

.stopwatchButtonHover {
  visibility: hidden;
}

.my-task-header:hover .stopwatchButtonHover {
  visibility: visible;
}

.my-task-header {
  height: 47px !important;
}

.task-title {
  display: inline-block;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>