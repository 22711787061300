<template>
  <div class="card col-12 pt-3">
    <div class="flex flex-wrap overflow-x-scroll justify-content-center sm:justify-content-between">
      <div class="flex text-center"></div>
      <div class="flex text-center">
        <div class="mr-3">
          <FilterSearchButton v-model="filters['global'].value" />
        </div>
        <div class="mr-3">
          <Button
            label="New Prospect"
            icon="las la-plus"
            @click="$router.push('/client?is_temp=1')"
          />
        </div>
      </div>
    </div>
    <DataTable
      :value="clients"
      responsiveLayout="scroll"
      :rowClass="rowClass"
      editMode="row"
      dataKey="uid"
      filterDisplay="menu"
      :filters.sync="filters"
      paginator
      :rows="30"
      :rowsPerPageOptions="[30, 50, 100]"
    >
      <template #empty>
        <Button
          label="New Prospect"
          class="p-button-text p-button-secondary"
          icon="las la-plus"
          @click="$router.push('/client?is_temp=1')"
        />
      </template>
      <!-- Columns -->
      <Column
        sortable
        header="Company"
        field="name"
        filterField="name"
        :styles="{ width: '20%' }"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Search by client name"
          />
        </template>
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else>
            <div @click="(ev) => nav(slotProps, ev)">
              <div class="cursor-pointer">{{ slotProps.data["name"] }}</div>
              <div class="font-light text-sm cursor-pointer">
                {{ slotProps.data.client_code }}
              </div>
            </div>
          </template>
        </template>
      </Column>
      <Column
        sortable
        filterField="contact"
        header="Contact"
        field="contact_name"
        :styles="{ width: '20%' }"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Search by account executive"
          />
        </template>
        <template #body="slotProps">
          <Skeleton
            class="mt-1"
            width="50%"
            v-if="isLoading"
          ></Skeleton>
          <template v-else>{{ slotProps.data.contact_name ?? "N/A" }}</template>
        </template>
      </Column>
      <Column
        sortable
        header="Email"
        field="contact_email"
        :styles="{ width: '30%' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="30%"
          ></Skeleton>
          <template v-else>{{
            slotProps.data.contact_email ?? "N/A"
          }}</template>
        </template>
      </Column>

      <Column
        sortable
        header="Billing Tier"
        field="billing_tier"
        :styles="{ width: '20%' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="10%"
          ></Skeleton>
          <template v-else>{{
            slotProps.data[slotProps.column.field] ?? "N/A"
          }}</template>
        </template>
      </Column>
      <Column
        sortable
        header="Active"
        field="active"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="40%"
          ></Skeleton>
          <template v-else>
            <AActiveSwitch
              :ref="slotProps.data.uid"
              @change="handleActive(slotProps.data)"
              v-model="slotProps.data[slotProps.column.field]"
            />
          </template>
        </template>
      </Column>
      <Column
        bodyStyle="text-align:right"
        :styles="{ width: '1rem' }"
      >
        <template #body="slotProps">
          <div class="flex justify-content-end align-items-center invisible cursor-pointer">
            <i
              @click.stop="(ev) => nav(slotProps, ev)"
              class="las la-pen i-20 grey pr-1"
            />
            <i
              @click="
                deletePrompt(
                  slotProps.data.name,
                  '/v1/clients/' + slotProps.data.uid
                )
              "
              class="las la-trash i-20 grey"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
  
  <script>
import { fetchClientsSummary, state } from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";

export default {
  name: "ProspectiveClients",
  data() {
    return {
      filteredClient: null,
      isLoading: true,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "account_executive.name": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
    };
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    this.isLoading = true;
    if (!state.clientsSummary) {
      state.clientsSummary = [{}, {}, {}, {}, {}, {}];
    }
    await this.loadData();
    this.isLoading = false;
  },
  methods: {
    async loadData() {
      await fetchClientsSummary(true);
    },
    handleActive(obj) {
      delete obj.account_executive;
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/clients/status/" + obj.uid,
          obj,
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: `${obj.company_name} ${
              obj.active == 1 ? "activated" : "deactivated"
            }`,
            life: 3000,
          });
        })
        .catch((error) => {
          this.$refs[obj.uid].inputVal = this.updateInputSwitch(
            this.$refs[obj.uid].value === 0 ? 1 : 0
          );
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            console.error(error);
          }
        });
    },
    nav(data, event) {
      const route = "/client/" + data.data.uid + "?is_temp=1";

      if (event && (event.ctrlKey || event.metaKey)) {
        // Ctrl key (or Cmd key on Mac) is pressed, open in new tab
        const url = this.$router.resolve(route).href;
        window.open(url, "_blank");
      } else {
        // Normal click, navigate in the same tab
        this.$router.push(route);
      }
    },
    rowClass() {
      return "row-accessories row-accessories2";
    },
  },
  computed: {
    clients() {
      return state.clientsSummary;
    },
  },
};
</script>
  
  <style scoped>
/* Add any scoped styles here */
</style>
  