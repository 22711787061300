<template>
  <div class="pt-3">
    <div v-if="isLoading">
      <RocketLoad />
    </div>
    <form
      v-else-if="account_details?.finance"
      @submit.prevent="submitHandler"
      v-form-enter
    >
      <div class="agencydesk-spacer" />

      <div class="p-fluid formgrid grid">
        <div class="col-12">
          <div class="field">Details</div>
        </div>
        <div class="col-12 md:col-6">
          <div class="p-fluid formgrid grid">
            <div class="field col-12 sm:col-4">
              <label
                class="font-light"
                for="Tax Rate %"
              >Tax Rate %</label>
              <InputText
                id="Tax Rate %"
                placeholder="Tax Rate %"
                v-model="account_details.finance.tax_rate"
                type="text"
                @input="isDirty = true"
              />
            </div>
            <div class="field col-12 sm:col-4">
              <label
                class="font-light"
                for="Tax Rate %"
              >Tax Abbreviation</label>
              <InputText
                id="Tax Rate %"
                placeholder="Tax Abbreviation"
                v-model="account_details.finance.tax_abbreviation"
                type="text"
                @input="isDirty = true"
              />
            </div>

            <div class="field col-12 sm:col-4">
              <label
                class="font-light"
                for="Tax No"
              >Tax No</label>
              <InputText
                id="Tax No"
                placeholder="Tax No"
                @input="isDirty = true"
                v-model="account_details.finance.tax_number"
                type="text"
              />
            </div>
            <div class="field col-12 sm:col-6">
              <label
                class="font-light"
                for="Home Currency"
              >Home Currency</label>

              <Dropdown
                placeholder="Home Currency"
                inputId="Home Currency"
                :options="finance_currency"
                dataKey="uid"
                optionLabel="name"
                @input="isDirty = true"
                v-model="account_details.currency"
              />
            </div>
            <div class="field col-12 sm:col-6">

              <label
                class="font-light"
                for="Min Time Increment (Minutes)"
              >Min Time Increment (Minutes)</label>
              <InputNumber
                id="Min Time Increment (Minutes)"
                showButtons
                v-model="account_details.finance.minimum_time_increment"
                placeholder="Min Time Increment (Minutes)"
                @input="isDirty = true"
              />
            </div>
          </div>
        </div>
        <div class="col-12 md:col-6 md:pt-0">
          <div class="field">
            <label
              for="Banking details"
              class="label"
            >Banking Details</label>
            <Textarea
              id="Banking details"
              placeholder="Banking Details"
              @input="isDirty = true"
              rows="5"
              :autoResize="true"
              v-model="account_details.finance.banking_details"
            />
          </div>
        </div>
        <div class="pt-3 col-12">
          <div class="field">Label Settings</div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label
              for="Cost Estimate Footer"
              class="label"
            >Cost Estimate Footer</label>
            <Textarea
              id="Cost Estimate Footer"
              @input="isDirty = true"
              placeholder="Cost Estimate Footer"
              rows="5"
              :autoResize="true"
              v-model="account_details.finance.cost_estimate_footer"
            />
          </div>
        </div>
        <div class="flex flex-row flex-wrap justify-content-start align-items-center">
          <div class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-3">
            <label
              class="font-light"
              for="Cost Estimate Prefix"
            >Cost Estimate Prefix</label>
            <InputText
              id="Cost Estimate Prefix"
              v-model="account_details.finance.cost_estimate_prefix"
              placeholder="Cost Estimate"
              type="text"
              @input="isDirty = true"
            />
          </div>
          <div class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-3">
            <label
              class="font-light"
              for="Cost Estimate Starting Number"
            >Starting Number</label>
            <InputText
              id="Cost Estimate Prefix Starting Number"
              placeholder="Starting Number"
              v-model="account_details.finance.cost_estimate_numbering_start"
              type="text"
              @input="isDirty = true"
            />
          </div>
        </div>
        <Divider class="ml-2 mr-2"></Divider>
        <div class="col-12 md:col-6">
          <div class="field">
            <label
              for="Purchase Footer"
              class="label"
            >Purchase Order Footer</label>
            <Textarea
              placeholder="Purchase Order Footer"
              id="Purchase Footer"
              @input="isDirty = true"
              rows="5"
              :autoResize="true"
              v-model="account_details.finance.purchase_order_footer"
            />
          </div>
        </div>
        <div class="flex flex-row flex-wrap justify-content-start align-items-center">
          <div class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-3">
            <label
              class="font-light"
              for="Purchase Order Prefix"
            >Purchase Order Prefix</label>
            <InputText
              id="Purchase Order Prefix"
              placeholder="Purchase Order"
              v-model="account_details.finance.purchase_order_prefix"
              type="text"
              @input="isDirty = true"
            />
          </div>
          <div class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-3">
            <label
              class="font-light"
              for="Purchase Order Prefix Starting Number"
            >Starting Number</label>
            <InputText
              placeholder="Starting Number"
              id="Purchase Order Prefix Starting Number"
              v-model="account_details.finance.purchase_order_numbering_start"
              type="text"
              @input="isDirty = true"
            />
          </div>
        </div>
        <Divider class="ml-2 mr-2"></Divider>
        <div class="col-12 md:col-6">
          <div class="field">
            <label
              for="Invoice Footer"
              class="label"
            >Invoice Footer</label>
            <Textarea
              id="Invoice Footer"
              placeholder="Invoice Footer"
              @input="isDirty = true"
              rows="5"
              :autoResize="true"
              v-model="account_details.finance.invoice_footer"
            />
          </div>
        </div>
        <div class="flex flex-row flex-wrap justify-content-start align-items-center">
          <div class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-3">
            <label
              class="font-light"
              for="Invoice Prefix"
            >Invoice Prefix</label>
            <InputText
              id="Invoice Prefix"
              placeholder="Invoice"
              v-model="account_details.finance.invoice_prefix"
              type="text"
              @input="isDirty = true"
            />
          </div>
          <div class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-3">
            <label
              class="font-light"
              for="Invoice Prefix Larger Than"
            >Starting Number</label>
            <InputText
              id="Invoice Prefix Starting Number"
              placeholder="Starting Number"
              v-model="account_details.finance.invoice_numbering_start"
              type="text"
              @input="isDirty = true"
            />
          </div>
        </div>
      </div>

      <div class="flex justify-content-between mt-5">
        <Button
          type="submit"
          label="SAVE"
        />
        <Button
          label="CANCEL"
          class="p-button-text p-button-secondary"
        />
      </div>
    </form>
  </div>
</template>
<script>
import {
  fetchAccounts,
  fetchCurrency,
  state,
} from "../../../services/data_service";

export default {
  data() {
    return {
      isLoading: false,
      isDirty: false,
      loadingText: "Loading...",
      estimateLabelOptions: [
        { name: "Cost Estimate", value: 0 },
        { name: "Quotation", value: 1 },
      ],
      financeObj: {
        tax_abbreviation: "",
        tax_rate: "",
        currency: "",
        tax_number: "",
        quotation: 0,
        cost_estimate_label: "",
        cost_estimate_prefix: "",
        cost_estimate_numbering_start: "",
        job_prefix: "",
        job_numbering_start: "",
        purchase_order_prefix: "",
        purchase_order_numbering_start: "",
        invoice_prefix: "",
        invoice_numbering_start: "",
        quotation_prefix: "",
        quotation_numbering_start: "",
        banking_details: "",
        cost_estimate_footer: "",
        invoice_footer: "",
        purchase_order_footer: "",
        // ... add more properties as needed
      },
    };
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  methods: {
    submitHandler() {
      this.account_details.finance.currency_id =
        this.account_details.currency.uid;
      this.isLoading = true;
      var form = new FormData();
      form.append(
        "account_finance",
        JSON.stringify(this.account_details.finance)
      );
      if (this.account_details.finance.quotation?.value === 1) {
        this.account_details.finance.quotation = 0;
      } else if (this.account_details.finance.quotation?.value === 0) {
        this.account_details.finance.quotation = 1;
      }

      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/accounts/finance",
          JSON.stringify(this.account_details.finance),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Details Saved",
            life: 3000,
          });
          this.isDirty = false;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
    async loadData() {
      fetchCurrency();
      await fetchAccounts();
    },
    getHeaderStyle(route) {
      if (this.$route.path == route) {
        return "font-weight: bold; color: #4D7CFE";
      }
    },
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    finance_currency() {
      return state.currencies;
    },
    account_details() {
      return state.accounts;
    },
    accountFinance() {
      return { ...this.financeObj, ...this.account_details.finance };
    },
  },
  async mounted() {
    await this.loadData();
    this.isLoading = false;
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      this.$confirm.require({
        message: "Continue without saving?",
        header: "Warning",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          next();
        },
        reject: () => {
          next(false);
        },
      });
    } else {
      next();
    }
  },

  name: "Home",

  metaInfo: {
    title: "Settings | Finance",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>

