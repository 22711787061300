<template>
  <div class="card pt-2">
    <div class="flex flex-wrap sm:justify-content-end justify-content-center align-items-center pb-2">

      <div class="mr-3">
        <FilterSearchButton v-model="filters['global'].value" />
      </div>
      <div class="mr-3">
        <Button
          label="New Recurring"
          icon="las la-plus"
          @click="$store.dispatch('setSliderView', 'RecurrenceSlider')"
        />
      </div>
    </div>

    <div>
      <DataTable
        responsiveLayout="scroll"
        :rowClass="rowClass"
        :value="jobs_Recurrence"
        dataKey="uid"
        :filters.sync="filters"
        filterDisplay="menu"
        :paginator="true"
        :rows="30"
        :rowsPerPageOptions="[30, 50, 100]"
      >
        <template #empty>
          <Button
            v-if="!filters['global'].value"
            label="New Recurring"
            class="p-button-text p-button-secondary"
            icon="las la-plus"
            @click="$store.dispatch('setSliderView', 'RecurrenceSlider')"
          />
          <div v-else>No Results</div>
        </template>

        <!-- Job Name Column -->
        <Column
          field="job_name"
          sortable
          header="Job Name"
          :styles="{ width: '20%' }"
        >
          <template #body="slotProps">
            <Skeleton
              width="55%"
              v-if="isLoading"
            ></Skeleton>
            <template v-else>
              <span
                class="cursor-pointer"
                @click="editRow(slotProps.data)"
              >
                {{ slotProps.data.job_name }}
              </span>
            </template>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by Job Name"
            />
          </template>
        </Column>

        <!-- Job Template Column -->
        <Column
          field="job_template"
          sortable
          header="Job Template"
          :styles="{ width: '20%' }"
        >
          <template #body="slotProps">
            <Skeleton
              width="55%"
              v-if="isLoading"
            ></Skeleton>
            <template v-else>
              {{ slotProps.data.job_template }}
            </template>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by Job Template"
            />
          </template>
        </Column>

        <!-- Repeat Next Column -->
        <Column
          field="recur_next"
          sortable
          header="Repeat Next"
          :styles="{ width: '15%' }"
        >
          <template #body="slotProps">
            <Skeleton
              width="60%"
              v-if="isLoading"
            ></Skeleton>
            <template v-else>
              {{ slotProps.data.recur_next }}
            </template>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by Repeat Next"
            />
          </template>
        </Column>

        <!-- Repeat Every Column -->
        <Column
          field="recur_interval"
          sortable
          header="Repeat Every"
          :styles="{ width: '15%' }"
        >
          <template #body="slotProps">
            <Skeleton
              width="60%"
              v-if="isLoading"
            ></Skeleton>
            <template v-else>
              {{ slotProps.data.recur_interval }}
            </template>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by Repeat Every"
            />
          </template>
        </Column>

        <!-- Until Column -->
        <Column
          field="recur_until"
          sortable
          header="Until"
          :styles="{ width: '15%' }"
        >
          <template #body="slotProps">
            <Skeleton
              width="60%"
              v-if="isLoading"
            ></Skeleton>
            <template v-else>
              {{ slotProps.data.recur_until }}
            </template>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by Until"
            />
          </template>
        </Column>

        <!-- Active Column -->
        <Column
          sortable
          field="active"
          header="Active"
          :styles="{ width: '10%' }"
        >
          <template #body="slotProps">
            <Skeleton
              width="4rem"
              height="2rem"
              borderRadius="16px"
              v-if="isLoading"
            ></Skeleton>
            <template v-else>
              <AActiveSwitch
                v-model="slotProps.data.active"
                @change="saveRow(slotProps.data)"
              />
            </template>
          </template>
        </Column>

        <!-- Action Column -->
        <Column
          bodyStyle="text-align:right"
          :styles="{ width: '1rem' }"
        >
          <template #body="slotProps">
            <div class="flex justify-content-end align-items-center invisible cursor-pointer">
              <i
                @click="deletePrompt(slotProps.data.job_name, '/v1/jobrecurrence/delete/' + slotProps.data.uid)"
                :class="getDeleteClass()"
                v-if="!isLoading"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { state, fetchJobsRecurrence } from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";
import FilterSearchButton from "../../../components/FilterSearchButton.vue";

export default {
  components: {
    FilterSearchButton,
  },
  data() {
    return {
      isLoading: true,
      isCollapse: true,
      drag: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        job_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        job_template: { value: null, matchMode: FilterMatchMode.CONTAINS },
        recur_interval: { value: null, matchMode: FilterMatchMode.CONTAINS },
        recur_until: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  computed: {
    jobs_Recurrence() {
      return state.jobsRecurrence;
    },
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },

  async mounted() {
    if (!this.jobs_Recurrence) {
      state.jobsRecurrence = [{}, {}, {}, {}, {}, {}]; // Placeholder for skeleton loading
    }
    await this.loadData();
    this.isLoading = false;
  },

  methods: {
    markDown(text) {
      return text
        .replace(/<\/p><p>/g, "<br>")
        .replace(/<p>/g, "")
        .replace(/<\/p>/g, "")
        .replace(/\n/g, "<br>");
    },
    toggleShowDescripton() {
      this.isCollapse = !this.isCollapse;
    },
    async loadData() {
      this.isLoading = true; // Start loading
      await fetchJobsRecurrence();
      this.isLoading = false; // End loading after data is fetched
    },
    rowClass() {
      return "row-accessories";
    },
    getEditClass() {
      return "las la-pen i-20 mr-3 grey";
    },
    getDeleteClass() {
      return "las la-trash i-20 grey";
    },
    editRow(rowData) {
      this.$store.dispatch("setSelectedObject", rowData);
      this.$store.dispatch("setSliderView", "RecurrenceSlider");
    },
    saveRow(r) {
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/jobrecurrence/" + r.uid, // Corrected API endpoint
          JSON.stringify(r),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Recurring Job Updated",
            life: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.$toast.add({
            severity: "error",
            summary: "Error Updating Recurring Job",
            detail: error.message,
            life: 3000,
          });
        });
    },
    deletePrompt(name, url) {
      this.$confirm.require({
        message: `Are you sure you want to delete <b>${name}</b>?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteRow(url);
        },
        reject: () => {
          //Do nothing
        },
      });
    },
    deleteRow(url) {
      this.isLoading = true;
      this.$axios
        .delete(process.env.VUE_APP_ROOT_API + url)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Recurring Job Deleted",
            life: 3000,
          });
          this.$bus.$emit("refreshData"); // Refresh data after delete
        })
        .catch((error) => {
          console.error(
            "There was an error deleting the recurring job!",
            error
          );
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Error Deleting Recurring Job",
            life: 3000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  metaInfo: {
    title: "Settings | Recurring",
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>

<style scoped>
</style>