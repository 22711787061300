<template>
  <div class="grid grid-nogutter min-h-screen">
    <!-- Left Column -->
    <div class="hidden lg:flex lg:col-6 bg-black-login flex-column p-6 relative h-screen">
      <!-- Centered Logo and Slogan -->
      <div
        class="flex flex-column align-items-center justify-content-center"
        style="height: 80vh"
      >
        <div class="flex align-items-center gap-3 mb-3">
          <img
            src="@/assets/logo_new.svg"
            style="height: 50px"
          />
        </div>
        <div class="text-white text-l">
          Plan • Schedule • Work • Grow
        </div>
      </div>

      <!-- Icon Grid - Positioned absolutely and hidden on small devices -->
      <div
        class="hidden lg:block absolute w-full left-0 px-6"
        style="bottom: 20%"
      >
        <div class="flex justify-content-center">
          <div
            class="grid grid-nogutter  justify-content-center"
            style="width: 50%"
          >
            <div class="col-2 flex align-items-center justify-content-center p-3">
              <i class="pi pi-calendar text-primary text-5xl"></i>
            </div>
            <div class="col-2 flex align-items-center justify-content-center p-3">
              <i class="pi pi-clock text-primary text-5xl"></i>
            </div>
            <div class="col-2 flex align-items-center justify-content-center p-3">
              <i class="pi pi-users text-primary text-5xl"></i>
            </div>
            <div class="col-2 flex align-items-center justify-content-center p-3">
              <i class="pi pi-chart-line text-primary text-5xl"></i>
            </div>
            <div class="col-2 flex align-items-center justify-content-center p-3">
              <i class="pi pi-file text-primary text-5xl"></i>
            </div>
            <div class="col-2 flex align-items-center justify-content-center p-3">
              <i class="pi pi-cog text-primary text-5xl"></i>
            </div>
            <div class="col-2 flex align-items-center justify-content-center p-3">
              <i class="pi pi-briefcase text-primary text-5xl"></i>
            </div>
            <div class="col-2 flex align-items-center justify-content-center p-3">
              <i class="pi pi-dollar text-primary text-5xl"></i>
            </div>
            <div class="col-2 flex align-items-center justify-content-center p-3">
              <i class="pi pi-building text-primary text-5xl"></i>
            </div>
            <div class="col-2 flex align-items-center justify-content-center p-3">
              <i class="pi pi-phone text-primary text-5xl"></i>
            </div>
            <div class="col-2 flex align-items-center justify-content-center p-3">
              <i class="pi pi-inbox text-primary text-5xl"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Column -->
    <div class="col-12 lg:col-6 surface-section flex align-items-center justify-content-center p-6 h-screen">
      <div class="w-full sm:w-10 md:w-8 lg:w-9 xl:w-6 mx-auto">
        <h2 class="text-3xl font-bold mb-5 text-center">Login</h2>

        <form
          v-if="!isLoading"
          method="post"
          action="/"
          @submit="submit"
          @keyup.enter="submit"
        >
          <div class="field">
            <label
              for="email"
              class="block text-900 font-light mb-2"
            >Email</label>
            <InputText
              id="email"
              name="email"
              autocomplete="email"
              v-model="email"
              type="email"
              class="w-full mb-4"
              placeholder="name@company.com"
            />
          </div>
          <div class="field">
            <div class="p-input-icon-right w-full">
              <label
                for="password"
                class="block text-900 font-light mb-2"
              >Password</label>

              <InputText
                id="password"
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
                placeholder="Enter your password"
                class="w-full mb-4"
              />
              <i
                :class="['i-18 las', showPassword ? 'la-eye' : 'la-eye-slash', 'cursor-pointer']"
                @click="showPassword = !showPassword"
              ></i>
            </div>
          </div>

          <Button
            type="submit"
            class="w-full mb-4"
            label="Sign in"
          />

          <div class="text-center mb-4">
            <router-link
              to="/forgot-password"
              class="text-primary font-medium"
            >
              Forgot Password?
            </router-link>
          </div>

          <p class="text-sm text-color-secondary text-center mb-5">
            By proceeding, you agree to the<br>
            <router-link
              to="/terms"
              class="text-primary font-medium"
            >Terms of Service</router-link>
            and
            <router-link
              to="/privacy"
              class="text-primary font-medium"
            >Privacy Policy</router-link>
          </p>

          <div class="text-center">
            <span class="text-color-secondary mr-1">Don't have an account?</span>
            <router-link
              to="/signup"
              class="text-primary font-medium"
            >Sign up</router-link>
          </div>
        </form>

        <div
          v-else
          class="flex align-items-center justify-content-center"
          style="min-height: 350px"
        >
          <ProgressSpinner />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {
    ProgressSpinner: () => import("primevue/progressspinner"),
  },
  data() {
    return {
      loadingText: "",
      isLoading: false,
      email: "",
      password: "",
      remember: false,
      redirectUrl: null,
      showPassword: false,
    };
  },
  mounted() {
    var redirectUrl = localStorage.getItem("redirectUrl");
    if (redirectUrl) {
      this.redirectUrl = redirectUrl;
    }
  },
  beforeDestroy() {
    localStorage.removeItem("redirectUrl");
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.isLoading = true;
      var form = new FormData();
      form.append("email", this.email);
      form.append("password", this.password);
      return this.$store
        .dispatch("authenticate", form)
        .then(() => {
          if (this.isAuth == true) {
            if (this.redirectUrl && this.redirectUrl != "/login") {
              this.$router.push(this.redirectUrl);
            } else {
              this.$router.push({ path: "/jobs/active" });
            }
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    userName() {
      var userName = this.$store.getters.user.userName;
      firstName = false;
      if (userName) {
        var names = userName.split(" ");
        var firstName = names[0];
      }
      return firstName;
    },
    isAuth() {
      return this.$store.getters.isAuthenticated;
    },
  },
  metaInfo: {
    title: "Login",
    meta: [{ vmid: "description", name: "description", content: "Login Page" }],
  },
};
</script>

<style scoped>
.p-progress-spinner {
  width: 50px;
  height: 50px;
}

.bg-black-login {
  background-color: #252631;
}
</style>