<template>
  <div class="card col-12 pt-1 md:pt-3">
    <div class="flex flex-wrap overflow-x-scroll justify-content-center sm:justify-content-between">
      <div class="hidden md:flex text-center">
        <div
          v-for="category in categories"
          :key="category.type"
          :class="getCategoryClass(category)"
          @click="filter(category.type)"
        >
          {{ category.label }}
          <Badge
            v-if="isLoading === false"
            :style="getBadgeStyle(category)"
            class="ml-2 hidden md:block"
            :value="getBadgeValue(category)"
          />
          <Badge
            v-else
            style="background-color: #e8ecef"
            class="ml-2"
          />
        </div>
      </div>

      <div class="text-center hidden md:flex">
        <div class="mr-3">
          <FilterSearchButton
            v-model="tableSearch"
            :debounce="300"
          />
        </div>
      </div>
    </div>
    <Dropdown
      class="w-full mb-2 md:hidden"
      :options="categories"
      v-model="selectedCategory"
      optionLabel="label"
      optionValue="type"
      placeholder="Filter by category"
    />

    <div>
      <DataTable
        :lazy="true"
        responsiveLayout="scroll"
        :value="isLoading ? jobsLoading : jobs"
        :rowClass="rowClass"
        editMode="row"
        dataKey="uid"
        filterDisplay="menu"
        :filters.sync="tableFilters"
        @row-click="navJob"
        :globalFilterFields="[
          'client.uid',
          'managed_by.uid',
        ]"
        :paginator="true"
        ref="dt"
        :rows.sync="tableRows"
        :totalRecords="tableRowCount"
        :rowsPerPageOptions="[30, 50, 100]"
        @page="onPage($event)"
        @sort="onSort($event)"
        @filter="onFilter($event)"
      >
        <template #empty>
          <Button
            :label="!showTemplates ? 'New Job' : 'New Template'"
            @click="
              $store.dispatch('setSelectedObject', null);
              $store.dispatch(
                'setSliderView',
                !showTemplates ? 'JobSlider' : 'JobTemplateSlider'
              );
            "
            class="p-button-text p-button-secondary"
            icon="las la-plus"
          />
        </template>

        <Column
          field="job_number"
          sortable
          header="No"
          :styles="{ whiteSpace: 'nowrap',width: '120px' }"
        >
          <template #body="slotProps">
            <Skeleton
              v-if="isLoading"
              width="75%"
            ></Skeleton>
            <template v-else>
              <div class="font-light-hover cursor-pointer">
                {{ slotProps.data?.job_number }}
              </div>
            </template>
          </template>
        </Column>

        <Column
          :styles="{
            width: '300px',
          }"
          field="client.name"
          sortField="client"
          filterField="client.uid"
          sortable
          :showFilterOperator="false"
          :showFilterMatchModes="false"
          :header="showTemplates ? 'Template Name' : 'Client'"
          filter
        >
          <template #filter="{ filterModel }">
            <Dropdown
              v-model="filterModel.value"
              :options="clientOptions"
              optionLabel="name"
              optionValue="uid"
              placeholder="Select Client"
            />
          </template>
          <template #body="slotProps">
            <Skeleton
              v-if="isLoading"
              width="150px"
            ></Skeleton>

            <template v-else>
              <div
                class="cursor-pointer"
                v-if="!showTemplates"
                :title="slotProps.data?.client?.name"
                style="
                  max-width: 300px;
                  overflow-x: clip;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ slotProps.data?.client?.name }}
              </div>
            </template>
          </template>
        </Column>

        <Column
          field="title"
          sortable
          sortField="name"
          :styles="{
            width: '320px',
          }"
          :header="showTemplates ? 'Template Name' : 'Job'"
        >
          <template #body="slotProps">
            <Skeleton
              v-if="isLoading"
              width="130px"
            ></Skeleton>

            <template v-else>
              <div
                class="cursor-pointer vertical-middle"
                style="
                  max-width: 300px;
                  overflow-x: clip;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
                v-tooltip.bottom="{
                        value: slotProps.data[slotProps.column.field],
                      }"
              >
                <span
                  class="text-10 mr-2 p-1"
                  :style="
                    slotProps.data.status == 'active'
                      ? 'background: rgb(244, 251, 239) !important; color: rgb(109, 210, 48) !important; border-radius: 6px;'
                      : 'background: #E8ECEF !important;borderRadius: 6px;'
                  "
                >
                  {{ toTitleCase(slotProps.data.status) }}</span> {{ slotProps.data[slotProps.column.field] }}

              </div>
            </template>
          </template>
        </Column>

        <Column
          v-if="!showTemplates"
          field="managed_by.name"
          sortable
          sortField="managed_by"
          :styles="{ whiteSpace: 'nowrap' }"
          :showFilterOperator="false"
          :showFilterMatchModes="false"
          header="AE"
          filter
        >
          <template #filter="{ filterModel }">

            <Dropdown
              v-model="filterModel.value"
              :options="account_managers"
              optionLabel="name"
              optionValue="uid"
              placeholder="Select AE"
            />

          </template>
          <template #body="slotProps">
            <div class="flex">
              <Skeleton
                v-if="isLoading"
                width="75%"
              ></Skeleton>

              <template v-else>
                <div class="cursor-pointer">
                  {{ slotProps.data.managed_by.name }}
                </div>
              </template>
            </div>
          </template>
        </Column>
        <Column
          sortable
          :styles="{ whiteSpace: 'nowrap' }"
          field="metrics.time.tracked"
          sortField="progress"
          v-if="!showTemplates"
          header="Progress"
        >
          <template #body="slotProps">
            <template v-if="isLoading">
              <Skeleton width="15%"></Skeleton>
              <Skeleton
                class="mt-1"
                width="30%"
              ></Skeleton>
            </template>
            <template v-else>
              <div class="text-md">
                {{
                  slotProps.data?.metrics.time.tracked
                    ? slotProps.data?.metrics.time.tracked
                    : "00:00"
                }}
                /
                {{
                  slotProps.data?.metrics.time.estimated
                    ? slotProps.data?.metrics.time.estimated
                    : "00:00"
                }}
              </div>
              <div class="flex align-items-center mt-1">
                <div
                  class="surface-300 border-round overflow-hidden w-full"
                  style="height: 6px"
                >
                  <div
                    class="h-full"
                    :style="getProgressStyle(slotProps.data)"
                  />
                </div>
              </div>
            </template>
          </template>
        </Column>
        <Column
          v-if="!showTemplates"
          sortable
          field="dueDate"
          header="Due"
          sortField="date_due"
          dataType="date"
          :filterMenuStyle="{ width: '10rem' }"
          :styles="{ whiteSpace: 'nowrap' }"
          filter
        >

          <template #body="slotProps">
            <template v-if="isLoading">
              <Skeleton width="35%"></Skeleton>
              <Skeleton
                class="mt-1"
                width="20%"
              ></Skeleton>
            </template>
            <template v-else>
              <div>
                {{ formatDate(slotProps.data.date_due?.date) }}
              </div>
              <div class="flex align-items-center">
                <div :class="
                    'date-range-dot ' +
                    (
                      slotProps.data.status ==='closed' ? 'grey-closed' :
                      slotProps.data.status ==='archived' ? 'grey-closed' :
                      slotProps.data?.date_due?.tag_class === 'green'
                      ? 'green-active'
                      : slotProps.data?.date_due?.tag_class)
                  "></div>
                <span class="font-light pl-1 text-sm">
                  {{ slotProps.data.status ==='closed' ? 'Closed' :
                      slotProps.data.status ==='archived' ? 'Archived' :
                   slotProps.data?.date_due?.time_until }}
                </span>
              </div>
            </template>
          </template>
        </Column>
        <Column
          field="metrics"
          v-if="!showTemplates"
        >
          <template #body="slotProps">
            <div
              v-if="slotProps.data.notifications > 0"
              class="flex justify-content-end align-items-center cursor-pointer"
            >
              <i
                :class="'las la-comments'"
                v-if="slotProps.data.notifications > 0"
                @click="navMessages(slotProps.data)"
                style="
                  font-size: 14px;
                  background-color: #6dd230;
                  color: white;
                  border-radius: 50%;
                  padding: 3px;
                "
              ></i>
            </div>
          </template>
        </Column>
        <Column bodyStyle="text-align:right">
          <template #body="slotProps">
            <div class="flex justify-content-end align-items-center invisible cursor-pointer">
              <i
                v-if="showTemplates"
                @click.stop="navEditTemplateMode(slotProps.data)"
                class="las la-suitcase i-20 grey pr-1"
              />
              <i
                @click.stop="navEditMode(slotProps.data)"
                class="las la-pen i-20 grey pr-1"
              />
              <i
                @click.stop="
                  archiveJob(
                    slotProps.data
                  )
                "
                :class="slotProps.data.status ==='archived' ?'las la-box-open i-20 grey' : 'las la-archive i-20 grey'"
              />
              <i
                @click.stop="
                  deletePrompt(
                    slotProps.data?.title,
                    '/v1/jobs/' + slotProps.data?.uid
                  )
                "
                class="las la-trash i-20 grey"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import {
  state,
  fetchJobs,
  fetchClientsSummary,
  fetchUsers,
} from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      tableRows: 30,
      tablePage: 0,
      tableSortField: null,
      tableCategory: "active",
      tableSearch: null,
      categories: [
        { type: "active", label: "Active", color: "#6dd230" },
        { type: "draft", label: "Draft", color: "#e8ecef" },
        { type: "closed", label: "Closed", color: "#e8ecef" },
        { type: "archived", label: "Archived", color: "#e8ecef" },
      ],
      isLoading: true,
      showTemplate: false,
      showTemplates: false,
      selectedCategory: null,
      statusOptions: [
        { label: "Active", value: "active" },
        { label: "Draft", value: "draft" },
        { label: "Closed", value: "closed" },
        { label: "Archived", value: "archived" },
        { label: "Accepted", value: "accepted" },
      ],
      tableFilters: {
        "managed_by.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "client.uid": { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      filters: ["active"],
      skipCategoryFilter: false,
    };
  },
  beforeDestroy() {
    this.$pusher.unsubscribe(this.account_uid);
    this.$bus.off("refreshData", this.loadData);
  },
  computed: {
    clientOptions() {
      return state.clientsSummary
        .filter((a) => a.active_jobs > 0)
        .map((client) => ({
          name: client.name + " (" + client.active_jobs + ")",
          uid: client.uid,
        }));
    },
    user() {
      return this.$store.getters.user;
    },
    account_uid() {
      return this.user?.account_uid;
    },
    jobs() {
      if (this.showTemplates !== true) {
        return state.jobs.filter((job) => job.is_template === 0);
      }
      return state.jobs.filter((job) => job.is_template === 1);
    },
    filteredJobs() {
      return this.filterJobs();
    },
    jobCounts() {
      return state.jobTotals;
    },
    tableRowCount() {
      return this.filters?.length > 0
        ? this.jobCounts[this.filters[0]]
        : this.jobCounts["total"];
    },
    jobsLoading() {
      return Array(this.tableRows).fill({});
    },
    account_managers() {
      return state.users.filter((user) => user.is_manager === 1);
    },

    hasAnyFilters() {
      return (
        this.tableSearch ||
        Object.values(this.tableFilters).some((filter) => filter.value !== null)
      );
    },
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },

  async mounted() {
    this.subscribeChannel();

    this.skipCategoryFilter = true;
    if (this.$store.getters.jobsFilters) {
      const savedFilters = this.$store.getters.jobsFilters;
      if (savedFilters.tableFilters) {
        this.tableFilters = savedFilters.tableFilters;
      }
      if (savedFilters.categoryFilters) {
        this.filters = savedFilters.categoryFilters;
      }
    }

    await Promise.all([this.loadData(), fetchClientsSummary(), fetchUsers()]);

    this.skipCategoryFilter = false;
  },
  methods: {
    archiveJob(job) {
      this.$confirm.require({
        message:
          "Are you sure you want to " +
          (job.status === "archived" ? "unarchive" : "archive") +
          " this job?",
        header:
          "Confirm " + (job.status === "archived" ? "Unarchive" : "Archive"),
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$axios
            .get(
              process.env.VUE_APP_ROOT_API +
                "/v1/jobs/" +
                job.uid +
                "/" +
                (job.status === "archived"
                  ? "job_archived=0"
                  : "job_archived=1"),
              {}
            )
            .then((result) => {
              this.$toast.add({
                severity: "success",
                summary:
                  job.job_number +
                  " has been " +
                  (job.status === "archived" ? "unarchived" : "archived"),
                life: 3000,
              });
              this.loadData();
            })
            .catch((error) => {
              this.$toast.add({
                severity: "error",
                summary: "Error archiving job",
                life: 3000,
              });
            });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    navMessages(ev) {
      this.$store.dispatch("setSelectedObject", ev);
      this.$router.push("/job/" + ev.uid + "/documents");
    },
    getCategoryClass(category) {
      return `flex align-items-center p-3 mr-3 font-light cursor-pointer ${this.getBottomBorderStyle(
        category
      )}`;
    },
    getBottomBorderStyle(category) {
      return this.filters.indexOf(category.type) >= 0
        ? this.getBottomBorderPositiveClass(category)
        : "bottom-border-invisible";
    },
    getBottomBorderPositiveClass(category) {
      return category.type === "active"
        ? "bottom-border-positive"
        : category.type === "accepted"
        ? "bottom-border-positive"
        : "bottom-border-gray";
    },
    getBadgeStyle(category) {
      return `background-color: ${category.color}`;
    },
    getBadgeValue(category) {
      const value = this.jobCounts[category.type]; // Assuming your data properties match the category types
      return value !== null ? value : 0;
    },

    async loadData() {
      // await fetchJobs(null, this.tableRows, this.tablePage + 1);
      this.isLoading = true;

      var filterList = [];

      Object.keys(this.tableFilters).forEach((key) => {
        if (this.tableFilters[key].value) {
          // field is key but remove .*** from the end
          var field = key.split(".")[0];

          filterList.push({
            field: field,
            value: this.tableFilters[key].value,
          });
        }
      });

      await fetchJobs(
        null,
        this.tableRows,
        this.tablePage + 1,
        this.filters[0],
        this.tableSortField,
        this.tableSearch,
        filterList
      );
      this.jobs.forEach((job) => {
        job.date_due.date = new Date(job.date_due.date);
        job.dueDate = new Date(job.date_due.date);
      });
      if (this.showTemplates) {
        this.showTemplate = this.showTemplates;
      }
      this.isLoading = false;
    },
    setCurrencySymbol(job) {
      let currency = null;
      if (this.clients) {
        for (let i = 0; i < this.clients.length; i++) {
          if (job.client.uid === this.clients[i].uid) {
            currency = this.clients[i].currency.description;
          }
        }
        return currency;
      }
      return null;
    },
    show() {
      this.$store.dispatch("setShowTemplates", this.showTemplate);
    },
    getProgressStyle(val) {
      if (val.metrics.time.tracked === null) {
        return;
      }

      var estimated =
        val.metrics.time.estimated !== null
          ? val.metrics.time.estimated
          : "00:00";

      var progress =
        (this.timeToSeconds(val.metrics.time.tracked) /
          this.timeToSeconds(estimated)) *
        100;

      if (progress >= 100) {
        return "background: #fe4d97;width:100%";
      } else if (progress >= 60) {
        return "background: #ffab2b;width:" + progress + "%";
      } else {
        return "background: #6dd230;width:" + progress + "%";
      }
    },
    rowClass() {
      return "row-accessories";
    },
    subscribeChannel() {
      var channel = this.$pusher.subscribe(this.account_uid);
      channel.bind("job", (data) => {
        this.loadData();
      });
    },
    navJob(ev) {
      const route = "/job/" + ev.data?.uid;
      if (ev.originalEvent.ctrlKey || ev.originalEvent.metaKey) {
        // Ctrl key (or Cmd key on Mac) is pressed, open in new tab
        const url = this.$router.resolve(route).href;
        window.open(url, "_blank");
      } else {
        // Normal click, navigate in the same tab
        this.$store.dispatch("setSelectedObject", ev.data);
        this.$router.push(route);
      }
    },
    formatDate(date) {
      if (date instanceof Date) {
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (month < 10) {
          month = "0" + month;
        }

        if (day < 10) {
          day = "0" + day;
        }

        return date.getFullYear() + "-" + month + "-" + day;
      }
      return date;
    },
    filter(f) {
      if (this.filters.indexOf(f) >= 0) {
        this.filters = this.filters.filter((item) => item !== f);
      } else {
        this.filters = [f];
      }
      this.saveFilters();
    },
    saveFilters() {
      this.$store.dispatch("setJobsFilters", {
        tableFilters: this.tableFilters,
        categoryFilters: this.filters,
      });
    },
    calcSpend(obj) {
      if (!obj.budget.amount || !obj.budget.spend) {
        return 0;
      }

      return ((obj.budget.spend / obj.budget.amount) * 100).toFixed(0);
    },
    navEditMode(ev) {
      this.$store.dispatch("setSelectedObject", ev);
      this.$store.dispatch("setSliderView", "JobSlider");
    },
    navEditTemplateMode(ev) {
      ev.job_from_template = 1;
      this.$store.dispatch("setSelectedObject", ev);
      this.$store.dispatch("setSliderView", "JobSlider");
    },
    onPage(event) {
      this.tablePage = event.page;
      this.loadData();
    },
    onSort(event) {
      var field = event.sortField;
      var order = event.sortOrder;
      if (order === 1) {
        this.tableSortField = "-" + field;
      } else {
        this.tableSortField = field;
      }
      this.loadData();
    },
    onFilter(event) {
      this.tableFilters = event.filters;
      this.skipCategoryFilter = true;
      this.filters = [];
      this.saveFilters();
      this.loadData();
    },
  },
  metaInfo: {
    title: "Jobs",
    meta: [{ vmid: "description", name: "description", content: "Jobs Page" }],
  },
  watch: {
    filters(n) {
      if (this.skipCategoryFilter) {
        this.skipCategoryFilter = false;
        return;
      }

      this.loadData();
    },
    tableSearch(n, o) {
      var oldLength = o?.length ?? 0;
      var newLength = n?.length ?? 0;
      if (newLength > oldLength) {
        this.skipCategoryFilter = true;
        this.filters = [];
      }

      this.loadData();
    },
  },
};
</script>

<style scoped>
.text-link-hover:hover {
  color: #4d7cfe !important;
}
</style>
