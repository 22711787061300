<template>
  <div class="min-h-screen">
    <Toast />

    <router-view />
  </div>
</template>

<script>
export default {
  methods: {},
  name: "LayoutLogin",
  data() {
    return {
      user: null,
      showMobileMenu: 0,
    };
  },

  metaInfo: {
    title: "",
    titleTemplate: "%s | Agencydesk",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { vmid: "description", name: "description", content: "AgencyDesk" },
    ],
    link: [{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" }],
  },
};
</script>

<style lang="scss" scoped>
// Remove the .body-container class as it's not needed with the new responsive layout
</style>
