<template>
  <div class="card pt-3">
    <div class="flex flex-wrap md:no-wrap overflow-x-scroll justify-content-around sm:justify-content-between pb-1">
      <div class="flex text-center">
        <JobTaskFilter
          :active="billable"
          :completed="completed"
          @filter="filter"
          :filters="taskFilters"
        />
      </div>
      <div class="flex text-center justify-content-end">
        <div class="mr-3">
          <FilterSearchButton v-model="search" />
        </div>
      </div>
    </div>

    <draggable
      handle=".p-datatable-thead"
      :animation="140"
      :list="tasks"
      @end="onEndGroup($event)"
      :disabled="!!editGroup"
    >
      <div
        v-for="u in tasks"
        :key="u.uid"
        :style="
          minimized.indexOf(u.uid) > -1 || u.tasks.length < 1
            ? 'margin-bottom:24px;'
            : 'margin-bottom:32px;'
        "
      >
        <div v-if="
            (hideCompletedTasks &&
              u.tasks.filter((task) => task.is_complete == 0).length > 0) ||
            !hideCompletedTasks
          ">
          <div v-if="
              ((taskFilters?.length > 0 || !!search) &&
                notFiltered(u.tasks)?.length > 0) ||
              (taskFilters?.length == 0 && !search)
            ">
            <div
              class="header-pointer"
              :id="u.uid"
              @click="handleHeaderClick"
            >
              <DataTable
                v-if="
                  (hideCompletedTasks &&
                    u.tasks.filter((task) => task.is_complete == 0).length >
                      0) ||
                  !hideCompletedTasks
                "
                :value="notFiltered(u.tasks)"
                responsiveLayout="scroll"
                :rowClass="rowClass"
                :name="'table-' + u.uid"
                class="dt-class transition-dt no-empty-div parent-div"
                editMode="row"
                dataKey="uid"
              >

                <Column
                  :styles="{ width: '35%' }"
                  field="title"
                >
                  <template #header>
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div
                      v-else-if="editGroup !== u.uid"
                      class="font-light "
                      style="text-wrap: nowrap"
                    >
                      <span class="mr-2">
                        <i
                          v-if="minimized.indexOf(u.uid) > -1"
                          class="las la-plus cursor-pointer"
                        ></i>
                        <i
                          v-else
                          class="las la-minus cursor-pointer"
                        ></i>

                      </span>
                      {{ u.title }}
                      <!-- <i
                        @click.stop="toggleGroupMenu(u, $event)"
                        class="las la-ellipsis-v hover-show ml-2"
                      ></i> -->

                    </div>
                    <template v-else>
                      <InputText
                        ref="groupInput"
                        v-model="editGroupTitle"
                        placeholder="Group Name"
                        @blur="handleBlur"
                        @keydown.enter="updateGroup(editGroupTitle,u)"
                        @keydown.escape="
                          editGroupTitle = null;
                          editGroup = false;
                        "
                      ></InputText>
                      <Button
                        icon="pi pi-check "
                        :disabled="!editGroupTitle"
                        class="p-button-rounded grey-button-flat p-button-text ml-1"
                        @click.stop="updateGroup(editGroupTitle,u)"
                      />
                      <Button
                        icon="pi pi-times "
                        class="p-button-rounded grey-button-flat p-button-text ml-1"
                        @click.stop="
                          editGroupTitle = null;
                          editGroup = false;
                        "
                      />
                    </template>
                  </template>
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else>
                      <!-- <div class="pb-1">
                    {{ slotProps.data.title }}
                  </div> -->

                      <template v-if="slotProps.data.tags && slotProps.data.tags">
                        <div class="flex gap-1 flex-wrap pb-1">
                          <span
                            @click.stop="expandTags()"
                            v-for="tag in slotProps.data.tags.filter(
                              (a) => a.name
                            )"
                            :key="tag.uid"
                          >
                            <Chip
                              :style="getStyle(tag.colour)"
                              class="tag-chip cursor-pointer"
                            >
                              <div :class="
                                  'flex p-1 font-bold tag-chip-not-expand ' +
                                  (tags_expanded === true
                                    ? 'tag-chip-expand'
                                    : '')
                                ">
                                {{ tag.name }}
                              </div>
                            </Chip>
                          </span>
                        </div>
                      </template>
                      <div class="flex vertical-middle">
                        <div class="flex align-items-center cursor-pointer">
                          <i
                            style="font-size: 16px"
                            v-if="slotProps.data.is_complete == 1"
                            class="las la-check-circle grey mr-1 task-icon-complete"
                          />
                          <i
                            style="font-size: 16px"
                            v-if="slotProps.data.in_review == 1"
                            class="las la-user-clock grey mr-1 task-icon-in-review"
                          />
                        </div>
                        <div
                          @click="nav(slotProps.data)"
                          class="p-1 text-link"
                          v-if="
                            slotProps.data.is_complete == 0 ||
                            !slotProps.data.is_complete
                          "
                        >
                          <span class="cursor-pointer">{{ slotProps.data.task_type_name }}
                            {{ slotProps.data.title ? " - " : "" }}
                            {{ slotProps.data.title }}
                          </span>
                        </div>
                        <div
                          class="p-1 text-link"
                          @click="nav(slotProps.data)"
                          v-if="slotProps.data.is_complete == 1"
                        >
                          <span class="flex align-items-center">
                            <span class="cursor-pointer completed-task-strike-text">{{ slotProps.data.task_type_name }}
                              {{ slotProps.data.title ? " - " : "" }}
                              {{ slotProps.data.title }}</span>
                          </span>
                        </div>
                        <div class="pl-2 flex invisible justify-content-end align-items-center cursor-pointer">
                          <i
                            style="
                              background-color: #f5f6f8;
                              border-radius: 50px;
                            "
                            @click.stop="navEditMode(slotProps.data)"
                            class="las la-pen i-18 grey mr-1 p-1 hover-border"
                          />
                        </div>
                      </div>

                      <div class="flex align-items-center cursor-pointer">
                        <template v-if="slotProps?.data?.metrics?.documents > 0">
                          <i
                            :class="
                              'las la-paperclip grey iconHover  mr-1' +
                              (slotProps.data.metrics.documents > 0
                                ? 'success'
                                : 'gray')
                            "
                            @click="navEditMode(slotProps.data, 'brief')"
                            style="font-size: 18px"
                          ></i><span class="font-light mr-2">{{
                            slotProps.data.metrics.documents
                          }}</span></template>
                        <template v-if="slotProps?.data?.metrics?.notes > 0">
                          <i
                            @click.stop="navEditMode(slotProps.data, 'notes')"
                            :class="
                              'las la-comments grey  mr-1  iconHover ' +
                              (slotProps.data.metrics.notes > 0
                                ? 'success'
                                : 'gray')
                            "
                            style="font-size: 18px"
                          ></i><span class="font-light mr-1">{{
                            slotProps.data.metrics.notes
                          }}</span></template>
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  :header="minimized.indexOf(u.uid) < 0 ? 'Status' : ''"
                  field=""
                  :styles="{ width: '8%' }"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else>

                      <span
                        v-if="slotProps.data.users?.filter((u) => u.minutes_planned > 0)?.length > 0"
                        @click="navToTaskSchedule(slotProps.data, $event)"
                        class="text-12 p-2"
                        style="background: rgb(244, 251, 239) !important; color: rgb(109, 210, 48) !important; border-radius: 6px;"
                      >
                        Scheduled

                      </span>

                      <template v-else-if="job && job.client && job.is_template !== 1">
                        <span v-if=" slotProps.data.is_complete == 1">Complete</span>

                        <span
                          v-else-if="slotProps.data.users?.filter((u) => u.active === 1).length > 0"
                          @click="navToTaskSchedule(slotProps.data, $event)"
                        > <span class="jt-hover-hide">{{slotProps.data.date_due?.time_until === "Overdue" ? "Overdue" :"In Progress"}}</span>
                          <span class="jt-hover-show">Schedule</span>
                        </span>
                        <span v-else> In Progress
                        </span>
                      </template>
                      <template v-if="job && job.is_template === 1">No Status</template>
                    </div>
                  </template>
                </Column>
                <Column
                  :header="minimized.indexOf(u.uid) < 0 ? 'Assigned to' : ''"
                  field="users"
                  :styles="{ width: '16%' }"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div
                      v-can-click="'admin'"
                      v-else
                      @click.stop="getUsers(slotProps.data)"
                      class="relative cursor-pointer"
                    >
                      <Avatar
                        v-if="
                          !slotProps.data.users ||
                          slotProps.data.users.length < 1 ||
                          slotProps.data.users.filter((u) => u.active === 1)
                            .length < 1
                        "
                        size="large"
                        :style="{
                          'background-color': '#F2F4F6',
                          color: '#B9C5D0',
                        }"
                        shape="circle"
                        icon="las la-user-plus"
                      />

                      <ProgressAvatar
                        v-for="(user, index) in slotProps.data.users.filter(
                          (u) => u.active === 1
                        )"
                        class="absolute"
                        :user="user"
                        :key="user.uid"
                        :image="user.profile_picture"
                        :stroke="3"
                        :progress="user.progress_bar"
                        :strokeColor="
                          user.progress_bar < 100 ? '#6dd230' : '#fe4d97'
                        "
                        innerStrokeColor="#ffffff"
                        :style="
                          'top:-25px;left:' + index * 25 + 'px;z-index:' + index
                        "
                        :radius="25"
                      />
                    </div>
                  </template>
                </Column>
                <Column
                  :header="minimized.indexOf(u.uid) < 0 ? 'Date Range' : ''"
                  :styles="{ width: '21%' }"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else>
                      <template v-if="job && job.client && job.is_template === 0">
                        {{ dateRange(slotProps.data) }}
                        <div class="flex align-items-center">
                          <div :class="
                              'date-range-dot ' +
                              (slotProps.data.date_due?.tag_class === 'green'
                                ? 'green-active'
                                : slotProps.data.date_due?.tag_class)
                            "></div>
                          <span class="font-light pl-1">
                            {{ slotProps.data.date_due?.time_until }}
                          </span>
                        </div>
                      </template>
                      <template v-else>
                        <div>Not Scheduled</div>
                        <div class="font-light">0 Days</div>
                      </template>
                    </div>
                  </template>
                </Column>

                <Column
                  :headerStyle="{ textWrap: 'nowrap' }"
                  :header="
                    minimized.indexOf(u.uid) < 0
                      ? 'Time'
                      : u.tasks.length +
                        ' task' +
                        (u.tasks.length == 1 ? '' : 's')
                  "
                  field="active"
                  :styles="{ width: '20%' }"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else>
                      {{
                        slotProps.data.hours ? slotProps.data.hours : "00:00"
                      }}
                      /
                      {{ slotProps.data.estimated_hours }}
                      <div class="flex align-items-center mt-1">
                        <div
                          v-if="job && job.client && job.is_template === 0"
                          class="surface-300 border-round overflow-hidden w-full"
                          style="height: 6px"
                        >
                          <div
                            class="h-full"
                            :style="getProgressStyle(slotProps.data)"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                </Column>

                <!-- <template #footer>
                <Button
                  :key="u.uid"
                  label="Add Task"
                  style="color: #b9c5d0"
                  class="p-button-text p-button-secondary ml-3"
                  @click.stop="
                    unsetTask();
                    setitemGroup(u.uid);
                    $store.dispatch('setSliderView', 'TaskSlider');
                  "
                  icon="las la-plus"
                />
              </template> -->
              </DataTable>
            </div>
          </div>
        </div>
        <ContextMenu
          :ref="'gm-'+u.uid"
          :popup="true"
          :model="groupMenuItems"
        />
      </div>
    </draggable>
    <ContextMenu
      ref="menuSchedule"
      :popup="true"
      :model="menuTaskUsers"
    />
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { fetchTasksJob, state } from "../../../services/data_service.js";
import { temp_state } from "../../../services/temp_state_service.js";
import { FilterMatchMode } from "primevue/api";
import JobTaskFilter from "@/application/Job/components/JobTaskFilter.vue";
export default {
  name: "Home",
  components: { draggable, JobTaskFilter },
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      taskFilters: [],
      addGroup: null,
      isLoading: false,
      newGroup: null,
      editGroup: null,
      hideCompletedTasks: false,
      taskTagUID: null,
      minimized: [],
      menuTaskUsers: [],
      groupMenuItems: [],
      search: null,
      path: [
        {
          header: "Tasks",
          icon: "la-check",
        },

        {
          header: "Notes",
          icon: "la-comments",
        },
        {
          header: "Files",
          icon: "la-folder-open",
        },
        {
          header: "Automations",
          icon: "la-robot",
        },
      ],
    };
  },
  async mounted() {
    this.subscribeChannel();
    await this.loadData();
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$pusher.unsubscribe(this.account_uid);
    this.$bus.off("refreshData", this.loadData);
  },
  methods: {
    toggleGroupMenu(groupData, event) {
      var groupRef = "gm-" + groupData.uid;

      this.groupMenuItems = [
        {
          label: "Edit Group",
          icon: "las la-pen",
          command: () => {
            this.editGroup = groupData.uid;
            this.editGroupTitle = groupData.title;
          },
        },

        {
          label: "Delete Group",
          icon: "las la-trash",
          command: () => {
            this.deletePrompt(
              groupData.title,
              "/v1/tasks/group/" + groupData.uid
            );
          },
        },
      ];

      this.$refs[groupRef][0].toggle(event);
    },
    navToTaskSchedule(taskData, event) {
      if (taskData.users?.length === 1) {
        var url =
          "/task-schedule?userId=" +
          taskData.users[0].uid +
          "&taskId=" +
          taskData.uid;
        this.$router.push(url);
      }
      this.menuTaskUsers = taskData.users.map((user) => {
        return {
          label: user.name,
          icon: "las la-user",
          command: () => {
            var url =
              "/task-schedule?userId=" + user.uid + "&taskId=" + taskData.uid;
            this.$router.push(url);
          },
        };
      });
      this.$refs.menuSchedule.toggle(event);
    },
    handleBlur() {
      setTimeout(() => {
        this.editGroup = false;
      }, 100);
    },
    filter(f) {
      var i = this.taskFilters.indexOf(f);
      if (i >= 0) {
        this.taskFilters.splice(i, 1);
      } else {
        this.taskFilters.push(f);
      }
    },
    notFiltered(arr) {
      var retArr = [];
      arr?.forEach((i) => {
        if (this.taskFilters?.length > 0) {
          if (
            this.taskFilters.indexOf("active") >= 0 &&
            i.billable == "1" &&
            i.is_complete == 0
          ) {
            retArr.push(i);
          } else if (
            this.taskFilters.indexOf("completed") >= 0 &&
            i.is_complete == 1
          ) {
            retArr.push(i);
          }
        } else {
          retArr.push(i);
        }
      });

      if (this.search) {
        retArr = retArr.filter((i) => {
          return (
            i.task_type_name
              ?.toLowerCase()
              .includes(this.search.toLowerCase()) ||
            i.title?.toLowerCase().includes(this.search.toLowerCase()) ||
            i.type_name?.toLowerCase().includes(this.search.toLowerCase()) ||
            i.description?.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      }
      return retArr;
    },
    getTableStyle(u) {
      var table = document.getElementsByName("table-" + u.uid)[0];

      if (table && !table.original_height) {
        table.original_height = table?.getBoundingClientRect()?.height;
      }

      var style =
        "-webkit-transition: max-height 0.3s ease;  -moz-transition: max-height 0.3s ease;  transition: max-height 0.3s ease;";
      style +=
        this.minimized.indexOf(u.uid) > -1
          ? "max-height:42px;overflow: hidden;"
          : "max-height:" +
            (table?.original_height ? table.original_height + "px;" : "100%;");

      return style;
    },
    async loadData() {
      if (!state.tasksJob) {
        this.isLoading = true;
        state.tasksJob = [];
        for (let index = 0; index < 3; index++) {
          state.tasksJob.push({
            tasks: [],
          });
          for (let index2 = 0; index2 < 3; index2++) {
            state.tasksJob[index].tasks.push({});
          }
        }
      }
      await fetchTasksJob(this.$route.params.uid, 1);
      this.isLoading = false;
    },
    subscribeChannel() {
      var channel = this.$pusher.subscribe(this.account_uid);

      channel.bind("task", (data) => {
        if ((data == 1 || data == 0) && this.sliderView === "TaskSlider") {
          this.loadData();
        }
        if (data.uid) {
          if (
            this.sliderView !== "TaskSlider" &&
            data.job.uid === this.job.uid
          ) {
            this.loadData();
          }
        } else {
          if (state.tasksJob && state.tasksJob.length > 0) {
            for (let index = 0; index < state.tasksJob.length; index++) {
              const group = state.tasksJob[index];
              if (group.tasks) {
                for (let index = 0; index < group.tasks.length; index++) {
                  const task = group.tasks[index];

                  if (task.uid === data) {
                    this.loadData();
                  }
                }
              }
            }
          }
        }
      });
    },
    getTaskTitle(obj) {
      if (obj.title != "null") {
        return obj.title;
      } else {
        return obj.task_type_name;
      }
    },
    expandTags() {
      temp_state.tagsExpanded = !temp_state.tagsExpanded;
    },

    getStyle(c) {
      if (c && c.charAt(0) !== "#") {
        c = "#" + c;
      }
      return "background-color: " + c + "; color:white;";
    },
    handleHeaderClick(ev) {
      var isHeader = false;
      var uid = null;
      if (ev && ev.composedPath) {
        const path = ev.composedPath();
        for (let element of path) {
          if (isHeader && uid) {
            break;
          }
          if (element.id) {
            uid = element.id;
          }
          if (element.tagName === "TH") {
            isHeader = true;
          }
          if (element.tagName === "TBODY" || element.tagName === "INPUT") {
            break;
          }
        }
      }
      if (uid) {
        if (this.editGroup === uid) {
          return;
        }

        this.expand(uid);
      }
    },
    dateRange(job) {
      let date_start = new Date(job?.date_start?.date);
      let date_due = new Date(job?.date_due?.date);

      if (date_start.getFullYear() == date_due.getFullYear()) {
        return (
          this.$moment(job.date_start?.nice, "DD MMM YYYY").format("DD MMM") +
          " - " +
          this.$moment(job.date_due?.nice, "DD MMM YYYY").format("DD MMM 'YY")
        );
      }
      return (
        this.$moment(job.date_start?.nice, "DD MMM YYYY").format("DD MMM 'YY") +
        " - " +
        this.$moment(job.date_due?.nice, "DD MMM YYYY").format("DD MMM 'YY")
      );
    },
    getDateRangeClass(date) {
      switch (date.tag_class) {
        case "green":
          return "in-progress-job";
        case "red":
          return "overdue-job";
        case "orange":
          return "tomorrow-job";
      }
      return "in-progress-job";
    },
    insertGroup() {
      this.addGroup = !this.addGroup;
      this.$nextTick((r) => {
        this.$refs.groupInput.$el.focus();
      });
    },
    setitemGroup(uid) {
      temp_state.defaultJobApprove = true;
      state.itemGroup = uid;
    },
    getHeaderStyle(route) {
      if (route === "Tasks") {
        return "font-weight: bold; color: #4D7CFE";
      }
    },
    unsetTask() {
      this.$store.dispatch("setSelectedObject", null);
      state.task = null;
      state.amendments = [];
      state.taskUsers = [];
    },
    getUsers(task) {
      if (!this.canView("admin")) {
        return;
      }
      this.$store.dispatch("setSelectedObject", { uid: task.uid });
      this.$store.dispatch("setPopupDialog", "UserSelect");
    },
    editDelete(u) {},
    onColReorder() {
      this.$toast.add({
        severity: "success",
        summary: "Column Reordered",
        life: 3000,
      });
    },

    rowClass(data) {
      return "row-accessories row-accessories2 row-accessories-drag no-padding-row";
    },
    getProgressStyle(task) {
      var progress = task.progress_bar ? task.progress_bar : 0;
      if (progress >= 100) {
        return "background: #fe4d97;width:100%";
      } else if (progress >= 60) {
        return "background: #ffab2b;width:" + progress + "%";
      } else {
        return "background: #6dd230;width:" + progress + "%";
      }
    },
    navEditMode(ev, mode) {
      state.editTaskMode = true;
      this.$store.dispatch("setSelectedObject", {
        uid: ev.uid,
        mode: mode,
      });
      this.$store.dispatch("setSliderView", "TaskSlider");
    },
    nav(data) {
      //
      this.$store.dispatch("setSelectedObject", {
        uid: data.uid,
      });
      this.$store.dispatch("setSliderView", "TaskSlider");
    },
    postGroup(n) {
      if (n && n.length > 0) {
        const job_uid = this.$route.params.uid;
        var form = new FormData();
        form.append("title", n);
        const title = n;
        form.append("job", job_uid);
        this.$axios
          .post(process.env.VUE_APP_ROOT_API + "/v1/tasks/group", form, {
            headers: { "Content-type": "application/x-www-form-urlencoded" },
          })
          .then((response) => {
            (this.newGroup = null),
              this.$toast.add({
                severity: "success",
                summary: "Group Saved",
                detail: title + " has been added",
                life: 3000,
              });
            this.addGroup = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error);
          });
      }
    },
    updateGroup(n, group) {
      if (n && n.length > 0) {
        group.title = n;
        var form = new FormData();
        form.append("title", n);
        const title = n;
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API + "/v1/tasks/group/" + this.editGroup,
            form,
            {
              headers: { "Content-type": "application/x-www-form-urlencoded" },
            }
          )
          .then((response) => {
            this.editGroup = false;
            this.$toast.add({
              severity: "success",
              summary: "Group Saved",
              detail: title + " has been updated",
              life: 3000,
            });
            this.addGroup = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error);
          });
      }
    },
    onEndGroup(e) {
      var form = new FormData();
      const job_uid = this.$route.params.uid;
      form.append("job", job_uid);
      var sortlist = "";
      this.tasks.forEach((value) => {
        sortlist += value.uid + ",";
      });
      form.append("sortlist", sortlist);
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + "/v1/tasks/group/sort", form, {
          headers: { "Content-type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          //
        })
        .catch((error) => {
          console.error(error);
        });
    },
    expand(uid) {
      const index = this.minimized.indexOf(uid);

      var table = document.getElementsByName("table-" + uid)[0];

      var rows = table.getElementsByTagName("tr");
      var height = 0;
      for (var i = 0; i < rows.length; i++) {
        height += rows[i].getBoundingClientRect().height;
      }

      if (index > -1 && table) {
        table.style.maxHeight = "100%";
      } else {
        table.style.maxHeight = height + "px";
        setTimeout(() => {
          table.style.overflow = "hidden";
          table.style.maxHeight = "42px";
        }, 300);
      }

      if (index > -1) {
        this.minimized.splice(index, 1); // 2nd parameter means remove one item only
      } else {
        this.minimized.push(uid);
      }
    },
    onRowReorder(e, ev) {
      e.tasks = ev.value;
      var form = new FormData();

      const job_uid = this.$route.params.uid;
      form.append("task", ev.value[ev.dragIndex]);
      form.append("group", e.uid);
      form.append("job", job_uid);
      var sortlist = "";

      e.tasks = ev.value;

      ev.value.forEach((value) => {
        sortlist += value.uid + ",";
      });
      form.append("sortlist", sortlist);
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + "/v1/tasks/group/move", form, {
          headers: { "Content-type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          //
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  beforeMount() {
    temp_state.tagsExpanded = false;
  },
  computed: {
    job() {
      return state.job;
    },
    tags_expanded() {
      return temp_state.tagsExpanded;
    },
    user() {
      return this.$store.getters.user;
    },
    account_uid() {
      return this.user?.account_uid;
    },
    tasks() {
      return state.tasksJob?.filter((a) => a.tasks?.length > 0) || [];
    },
    completed() {
      var o = 0;
      if (this.tasks) {
        this.tasks.forEach((g) => {
          g.tasks.forEach((t) => {
            if (t.is_complete == 1) o++;
          });
        });
      }
      return o;
    },
    billable() {
      var o = 0;
      if (this.tasks) {
        this.tasks.forEach((g) => {
          g.tasks.forEach((t) => {
            if (t.billable == 1 && t.is_complete !== 1) o++;
          });
        });
      }
      return o;
    },
  },
  watch: {
    async isActive(n, o) {
      if (n === true) {
        this.loadData();
      }
    },
  },
};
</script>
<style>
.slide-fade-enter-active {
  transition: all 0.4s ease-in;
  transition-delay: 0.2s !important;
}

.slide-fade-enter-from {
  transform: translateX(50px);
  opacity: 0;
}
.task-icon-complete {
  color: #6dd230 !important;
}
.grey-out {
  opacity: 0.5 !important;
  /* background-color: rgb(249, 249, 249) !important; */
}

.p-datatable-footer {
  background: none !important;
}

.row-accessories2 > td > div > span > .jt-hover-hide {
  display: block !important;
}

.row-accessories2:hover > td > div > span > .jt-hover-hide {
  display: none !important;
}

.row-accessories2 > td > div > span > .jt-hover-show {
  display: none !important;
}

.row-accessories2:hover > td > div > span > .jt-hover-show {
  display: block !important;
}
</style>