
<template>
  <th
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="['max-300 flex header task-header pr-2', taskClasses]"
    :id="'ut-' + user.uid +'-'+ task.uid"
  >

    <div class="cursor-grab col-1 align-self-center handle">
      <i
        v-if="hover"
        class="la la-grip-lines-vertical"
      />
      <div
        class="task-notice"
        style="position: absolute"
        v-if="!hover && task.date_due && task.date_due.time_until == 'Overdue'"
      />
    </div>
    <div class="col-7 p-02 justify-content-start align-self-center flex-column">
      <div
        @click="getTask(task.uid)"
        :class="['font-weight-normal cursor-pointer text-start', { 'text-ellipsis-wrap': !expand }]"
      >
        <span
          v-tooltip.bottom="{
            value: task.job?.title,
          }"
          class="font-light font-smaller"
        >{{ task.job?.client }}</span><br />
        {{ task.title ? task.title : task.task_type_name }}
      </div>
      <transition-expand>
        <div
          class="font-light justify-content-start align-self-center flex font-smaller"
          v-if="expand"
        >
          <span>{{ task.minutes ? secToHM(task.minutes * 60) : "00:00" }} /
            {{ task.expected_hours ? task.expected_hours : "0:00" }}</span>
        </div>
      </transition-expand>
    </div>
    <div class="col inline-flex align-self-center justify-content-between">
      <div class="col p-0">
        <span
          class="p-badge p-component w-3rem"
          :style="
            isExceeded(task)
              ? 'background-color: #ffab2b'
              : task.hours_unplanned &&
                task.hours_planned !== task.estimated_hours &&
                task.minutes_unplanned > 0 &&
                task.hours_unplanned != '00:00'
              ? 'background-color: #6dd230'
              : 'background-color: transparent'
          "
        >
          <i
            v-if="isExceeded(task)"
            class="las la-hourglass-end text-16 text-center"
            style="color: white; padding-top: 2px"
          />
          <span
            v-else
            :style="
              task.hours_unplanned &&
              task.hours_planned !== task.estimated_hours &&
              task.minutes_unplanned > 0 &&
              task.hours_unplanned != '00:00'
                ? ''
                : 'background-color: transparent'
            "
          ><template v-if="
                task.hours_unplanned &&
                task.hours_planned !== task.estimated_hours &&
                task.minutes_unplanned > 0 &&
                task.hours_unplanned != '00:00'
              ">{{
                task.hours_unplanned
                  ? task.hours_unplanned
                  : task.estimated_hours
              }}</template><template v-else>00:00</template></span>
        </span>
      </div>
      <div
        v-show="hover"
        class="absolute right-0 mr-1 align-self-center"
      >
        <!-- <i
          :class="
            'cursor-pointer align-self-center  font-smaller ' +
            (expand ? ' la la-minus' : ' la la-plus')
          "
          @click="expand = !expand"
        /> -->
        <!-- <i
          class="cursor-pointer la la-ellipsis-v align-self-center"
          @click="toggleMenu"
        /> -->
      </div>
    </div>
  </th>
</template>

<script>
export default {
  name: "TaskScheduleRow",
  props: {
    index: {
      type: Number,
    },
    days: {
      type: Object,
    },
    selectedCells: {
      type: Array,
    },
    user: {
      type: Object,
    },
  },
  computed: {
    taskClasses() {
      return {
        "border-bottom-unplanned":
          this.task.isBottomUnplanned && !this.isLastIndex,
        "background-unplanned": this.hasUnplanned,
        hovered: this.hover,
      };
    },
    task() {
      return this.user.tasks[this.index];
    },
    isBottomUnplanned() {
      return this.task.isBottomUnplanned;
    },
    isLastIndex() {
      return this.index === this.user.tasks.length - 1;
    },
    hasUnplanned() {
      return !!this.task.hours_unplanned && !!this.task.hours_unplanned;
    },
  },
  data() {
    return {
      hover: false,
      expand: false,
      items: [
        {
          label: "Update",
          icon: "pi pi-refresh",
          command: () => {
            this.$toast.add({
              severity: "success",
              summary: "Updated",
              detail: "Data Updated",
              life: 3000,
            });
          },
        },
        {
          label: "Delete",
          icon: "pi pi-times",
          command: () => {
            this.$toast.add({
              severity: "warn",
              summary: "Delete",
              detail: "Data Deleted",
              life: 3000,
            });
          },
        },
        {
          label: "Vue Website",
          icon: "pi pi-external-link",
          url: "https://vuejs.org/",
        },
        {
          label: "Router",
          icon: "pi pi-upload",
          to: "/fileupload",
        },
      ],
    };
  },
  methods: {
    getTask() {
      this.$store.dispatch("setSelectedObject", this.task.uid);
      this.$store.dispatch("setSliderView", "TaskSlider");
    },
    toggleMenu(event) {
      this.$emit("toggle", event);
    },
    isExceeded(t) {
      return parseFloat(t.minutes) > parseFloat(t.estimated_minutes);
    },
  },
};
</script>

<style>
.task-notice {
  background-color: #fe4d97;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 35%;
}

.exceed-notice {
  background-color: #ffab2b;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 35%;
}

.text-start {
  text-align: start;
}

.border-bottom-unplanned {
  border-bottom: 4px solid #f2f4f6;
}
.background-unplanned {
  background-color: #fdfdfd;
}

.max-300 {
  max-width: 300px !important;
}
</style>