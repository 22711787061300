<template>
  <div>
    <div
      v-for="(chart, index) in charts"
      :key="index"
      class="card col-12 pt-3"
    >
      <Chart
        v-if="!loading"
        type="bar"
        :data="chart.chartData"
        :options="chart.chartOptions"
        :style="getChartHeight(chart.chartData)"
      />

      <Skeleton
        v-else
        height="250px"
      ></Skeleton>

    </div>
  </div>
</template>

<script>
import { fetchReports, state } from "../../../services/data_service";

export default {
  data() {
    return {
      loading: true,
      chartDefinitions: [
        {
          title: "Active Work (hrs) by Department",
          dataKey: "departments",
          indexAxis: "y",
        },
        {
          title: "Active Work (hrs) by Category",
          dataKey: "categories",
          indexAxis: "y",
        },
        {
          title: "Active Work (hrs) by Account Executive",
          dataKey: "ae",
          indexAxis: "y",
        },
        {
          title: "Active Work (hrs) by Team Member",
          dataKey: "users",
          indexAxis: "y",
        },
        {
          title: "Active Work (hrs) by Month",
          dataKey: "dates",
          indexAxis: "x",
        },
      ],
    };
  },
  computed: {
    reports() {
      return state.reports;
    },
    charts() {
      return this.chartDefinitions.map((definition) => ({
        ...definition,
        chartData: {
          labels: [],
          datasets: [],
        },
        chartOptions: this.getChartOptions(
          definition.displayTitle || definition.title,
          definition.indexAxis
        ),
      }));
    },
  },
  methods: {
    getChartOptions(title, indexAxis) {
      return {
        plugins: {
          legend: {
            display: true,
            position: "top",
          },
          title: {
            display: true,
            text: title,
          },
        },
        indexAxis: indexAxis,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            stacked: true,
            grid: {
              drawBorder: false,
            },
          },
        },
      };
    },
    async loadData() {
      this.loading = true;
      await fetchReports();
      this.loading = false;
      this.updateAllChartData();
    },
    updateAllChartData() {
      this.charts.forEach((chart) => this.updateChartData(chart));
    },
    updateChartData(chart) {
      const reports = this.reports?.[chart.dataKey];
      if (!reports) return;

      chart.chartData.labels = reports.map((report) => report.name);
      chart.chartData.datasets = [
        {
          label: "Unbilled",
          barPercentage: 0.7,
          backgroundColor: "#4D7CFE",
          data: reports.map((report) => {
            return Math.max(
              0,
              Math.round(report.minutes_total / 60) -
                Math.round(report.minutes_billed / 60)
            );
          }),
        },
        {
          label: "Billed",
          backgroundColor: "#B9C5D0",
          barPercentage: 0.7,
          data: reports.map((report) => Math.round(report.minutes_billed / 60)),
        },
      ];
    },
    getChartHeight(chartData) {
      return {
        height: `${chartData.labels.length * 45}px`,
      };
    },
  },
  watch: {
    reports: {
      handler() {
        this.updateAllChartData();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>