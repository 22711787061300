var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex p-2 align-items-center mb-2 justify-content-between"},[_c('div',{staticClass:"flex align-items-center"},[_c('h1',{staticClass:"mr-4 mb-unset"},[_vm._v(" "+_vm._s(_vm.isTimesheetView == 1 ? "Timesheets" : "Task Schedule ")+" ")]),_c('div',{staticClass:"hidden sm:flex task-schedule align-items-center"},[(_vm.isTimesheetView != 1)?_c('Dropdown',{staticClass:"mr-3",staticStyle:{"width":"207px"},attrs:{"options":_vm.projectManagers,"optionLabel":"name","disabled":_vm.projectManagers.length == 0 || _vm.isLoading,"optionValue":"uid","placeholder":"Account Managers"},on:{"change":function($event){return _vm.$emit('filter', {
          key: 'account_manager',
          value: $event.value,
        })}},model:{value:(_vm.am),callback:function ($$v) {_vm.am=$$v},expression:"am"}}):_vm._e(),(_vm.isTimesheetView != 1)?_c('div',{class:' mr-6 p-1  ' +
          (_vm.filters.indexOf('overdue') >= 0
            ? 'bottom-border-pink'
            : 'bottom-border-invisible'),on:{"click":function($event){return _vm.filter('overdue')}}},[_c('p',{staticClass:"font-light cursor-pointer"},[_vm._v(" Overdue "),_c('Badge',{staticClass:"ml-2",staticStyle:{"background-color":"#fe4d97"},attrs:{"value":_vm.overdue}})],1)]):_vm._e(),(_vm.isTimesheetView != 1)?_c('div',{class:'mr-6 p-1 ' +
          (_vm.filters.indexOf('overrun') >= 0
            ? 'bottom-border-warning'
            : 'bottom-border-invisible'),on:{"click":function($event){return _vm.filter('overrun')}}},[_c('p',{staticClass:"font-light cursor-pointer"},[_vm._v(" Overrun "),_c('Badge',{staticClass:"ml-2",staticStyle:{"background-color":"#ffab2b"},attrs:{"value":_vm.overrun}})],1)]):_vm._e(),(_vm.isTimesheetView != 1)?_c('div',{class:'mr-6 p-1 ' +
          (_vm.filters.indexOf('unplanned') >= 0
            ? 'bottom-border-positive'
            : 'bottom-border-invisible'),on:{"click":function($event){return _vm.filter('unplanned')}}},[_c('p',{staticClass:"font-light cursor-pointer"},[_vm._v(" Unplanned Time "),_c('Badge',{staticClass:"ml-2",staticStyle:{"background-color":"#6dd230"},attrs:{"value":_vm.unplanned}})],1)]):_vm._e()],1)]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex align-items-center"},[_c('Button',{attrs:{"disabled":_vm.isLoading,"label":_vm.isTimesheetView == 1 ? 'Task Schedule' : 'Timesheets'},on:{"click":function($event){_vm.isTimesheetView == 1
            ? (_vm.isTimesheetView = 0)
            : (_vm.isTimesheetView = 1);
          _vm.$emit('switchView', _vm.isTimesheetView);}}})],1),_c('Button',{staticClass:"p-button-text p-button-secondary",class:{ 'spin-animation': _vm.isRefreshing },attrs:{"icon":"las la-sync"},on:{"click":function($event){return _vm.$emit('refresh')}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }