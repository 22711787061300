var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card pt-3"},[_c('div',{staticClass:"flex flex-wrap md:no-wrap overflow-x-scroll justify-content-around sm:justify-content-between pb-1"},[_c('div',{staticClass:"flex text-center"},[_c('JobTaskFilter',{attrs:{"active":_vm.billable,"completed":_vm.completed,"filters":_vm.taskFilters},on:{"filter":_vm.filter}})],1),_c('div',{staticClass:"flex text-center justify-content-end"},[_c('div',{staticClass:"mr-3"},[_c('FilterSearchButton',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]),_c('draggable',{attrs:{"handle":".p-datatable-thead","animation":140,"list":_vm.tasks,"disabled":!!_vm.editGroup},on:{"end":function($event){return _vm.onEndGroup($event)}}},_vm._l((_vm.tasks),function(u){return _c('div',{key:u.uid,style:(_vm.minimized.indexOf(u.uid) > -1 || u.tasks.length < 1
          ? 'margin-bottom:24px;'
          : 'margin-bottom:32px;')},[(
          (_vm.hideCompletedTasks &&
            u.tasks.filter((task) => task.is_complete == 0).length > 0) ||
          !_vm.hideCompletedTasks
        )?_c('div',[(
            ((_vm.taskFilters?.length > 0 || !!_vm.search) &&
              _vm.notFiltered(u.tasks)?.length > 0) ||
            (_vm.taskFilters?.length == 0 && !_vm.search)
          )?_c('div',[_c('div',{staticClass:"header-pointer",attrs:{"id":u.uid},on:{"click":_vm.handleHeaderClick}},[(
                (_vm.hideCompletedTasks &&
                  u.tasks.filter((task) => task.is_complete == 0).length >
                    0) ||
                !_vm.hideCompletedTasks
              )?_c('DataTable',{staticClass:"dt-class transition-dt no-empty-div parent-div",attrs:{"value":_vm.notFiltered(u.tasks),"responsiveLayout":"scroll","rowClass":_vm.rowClass,"name":'table-' + u.uid,"editMode":"row","dataKey":"uid"}},[_c('Column',{attrs:{"styles":{ width: '35%' },"field":"title"},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.isLoading)?_c('Skeleton'):(_vm.editGroup !== u.uid)?_c('div',{staticClass:"font-light",staticStyle:{"text-wrap":"nowrap"}},[_c('span',{staticClass:"mr-2"},[(_vm.minimized.indexOf(u.uid) > -1)?_c('i',{staticClass:"las la-plus cursor-pointer"}):_c('i',{staticClass:"las la-minus cursor-pointer"})]),_vm._v(" "+_vm._s(u.title)+" ")]):[_c('InputText',{ref:"groupInput",refInFor:true,attrs:{"placeholder":"Group Name"},on:{"blur":_vm.handleBlur,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.updateGroup(_vm.editGroupTitle,u)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined))return null;_vm.editGroupTitle = null;
                        _vm.editGroup = false;}]},model:{value:(_vm.editGroupTitle),callback:function ($$v) {_vm.editGroupTitle=$$v},expression:"editGroupTitle"}}),_c('Button',{staticClass:"p-button-rounded grey-button-flat p-button-text ml-1",attrs:{"icon":"pi pi-check ","disabled":!_vm.editGroupTitle},on:{"click":function($event){$event.stopPropagation();return _vm.updateGroup(_vm.editGroupTitle,u)}}}),_c('Button',{staticClass:"p-button-rounded grey-button-flat p-button-text ml-1",attrs:{"icon":"pi pi-times "},on:{"click":function($event){$event.stopPropagation();_vm.editGroupTitle = null;
                        _vm.editGroup = false;}}})]]},proxy:true},{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_c('div',[(slotProps.data.tags && slotProps.data.tags)?[_c('div',{staticClass:"flex gap-1 flex-wrap pb-1"},_vm._l((slotProps.data.tags.filter(
                            (a) => a.name
                          )),function(tag){return _c('span',{key:tag.uid,on:{"click":function($event){$event.stopPropagation();return _vm.expandTags()}}},[_c('Chip',{staticClass:"tag-chip cursor-pointer",style:(_vm.getStyle(tag.colour))},[_c('div',{class:'flex p-1 font-bold tag-chip-not-expand ' +
                                (_vm.tags_expanded === true
                                  ? 'tag-chip-expand'
                                  : '')},[_vm._v(" "+_vm._s(tag.name)+" ")])])],1)}),0)]:_vm._e(),_c('div',{staticClass:"flex vertical-middle"},[_c('div',{staticClass:"flex align-items-center cursor-pointer"},[(slotProps.data.is_complete == 1)?_c('i',{staticClass:"las la-check-circle grey mr-1 task-icon-complete",staticStyle:{"font-size":"16px"}}):_vm._e(),(slotProps.data.in_review == 1)?_c('i',{staticClass:"las la-user-clock grey mr-1 task-icon-in-review",staticStyle:{"font-size":"16px"}}):_vm._e()]),(
                          slotProps.data.is_complete == 0 ||
                          !slotProps.data.is_complete
                        )?_c('div',{staticClass:"p-1 text-link",on:{"click":function($event){return _vm.nav(slotProps.data)}}},[_c('span',{staticClass:"cursor-pointer"},[_vm._v(_vm._s(slotProps.data.task_type_name)+" "+_vm._s(slotProps.data.title ? " - " : "")+" "+_vm._s(slotProps.data.title)+" ")])]):_vm._e(),(slotProps.data.is_complete == 1)?_c('div',{staticClass:"p-1 text-link",on:{"click":function($event){return _vm.nav(slotProps.data)}}},[_c('span',{staticClass:"flex align-items-center"},[_c('span',{staticClass:"cursor-pointer completed-task-strike-text"},[_vm._v(_vm._s(slotProps.data.task_type_name)+" "+_vm._s(slotProps.data.title ? " - " : "")+" "+_vm._s(slotProps.data.title))])])]):_vm._e(),_c('div',{staticClass:"pl-2 flex invisible justify-content-end align-items-center cursor-pointer"},[_c('i',{staticClass:"las la-pen i-18 grey mr-1 p-1 hover-border",staticStyle:{"background-color":"#f5f6f8","border-radius":"50px"},on:{"click":function($event){$event.stopPropagation();return _vm.navEditMode(slotProps.data)}}})])]),_c('div',{staticClass:"flex align-items-center cursor-pointer"},[(slotProps?.data?.metrics?.documents > 0)?[_c('i',{class:'las la-paperclip grey iconHover  mr-1' +
                            (slotProps.data.metrics.documents > 0
                              ? 'success'
                              : 'gray'),staticStyle:{"font-size":"18px"},on:{"click":function($event){return _vm.navEditMode(slotProps.data, 'brief')}}}),_c('span',{staticClass:"font-light mr-2"},[_vm._v(_vm._s(slotProps.data.metrics.documents))])]:_vm._e(),(slotProps?.data?.metrics?.notes > 0)?[_c('i',{class:'las la-comments grey  mr-1  iconHover ' +
                            (slotProps.data.metrics.notes > 0
                              ? 'success'
                              : 'gray'),staticStyle:{"font-size":"18px"},on:{"click":function($event){$event.stopPropagation();return _vm.navEditMode(slotProps.data, 'notes')}}}),_c('span',{staticClass:"font-light mr-1"},[_vm._v(_vm._s(slotProps.data.metrics.notes))])]:_vm._e()],2)],2)]}}],null,true)}),_c('Column',{attrs:{"header":_vm.minimized.indexOf(u.uid) < 0 ? 'Status' : '',"field":"","styles":{ width: '8%' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_c('div',[(slotProps.data.users?.filter((u) => u.minutes_planned > 0)?.length > 0)?_c('span',{staticClass:"text-12 p-2",staticStyle:{"background":"rgb(244, 251, 239) !important","color":"rgb(109, 210, 48) !important","border-radius":"6px"},on:{"click":function($event){return _vm.navToTaskSchedule(slotProps.data, $event)}}},[_vm._v(" Scheduled ")]):(_vm.job && _vm.job.client && _vm.job.is_template !== 1)?[( slotProps.data.is_complete == 1)?_c('span',[_vm._v("Complete")]):(slotProps.data.users?.filter((u) => u.active === 1).length > 0)?_c('span',{on:{"click":function($event){return _vm.navToTaskSchedule(slotProps.data, $event)}}},[_c('span',{staticClass:"jt-hover-hide"},[_vm._v(_vm._s(slotProps.data.date_due?.time_until === "Overdue" ? "Overdue" :"In Progress"))]),_c('span',{staticClass:"jt-hover-show"},[_vm._v("Schedule")])]):_c('span',[_vm._v(" In Progress ")])]:_vm._e(),(_vm.job && _vm.job.is_template === 1)?[_vm._v("No Status")]:_vm._e()],2)]}}],null,true)}),_c('Column',{attrs:{"header":_vm.minimized.indexOf(u.uid) < 0 ? 'Assigned to' : '',"field":"users","styles":{ width: '16%' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_c('div',{directives:[{name:"can-click",rawName:"v-can-click",value:('admin'),expression:"'admin'"}],staticClass:"relative cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.getUsers(slotProps.data)}}},[(
                        !slotProps.data.users ||
                        slotProps.data.users.length < 1 ||
                        slotProps.data.users.filter((u) => u.active === 1)
                          .length < 1
                      )?_c('Avatar',{style:({
                        'background-color': '#F2F4F6',
                        color: '#B9C5D0',
                      }),attrs:{"size":"large","shape":"circle","icon":"las la-user-plus"}}):_vm._e(),_vm._l((slotProps.data.users.filter(
                        (u) => u.active === 1
                      )),function(user,index){return _c('ProgressAvatar',{key:user.uid,staticClass:"absolute",style:('top:-25px;left:' + index * 25 + 'px;z-index:' + index),attrs:{"user":user,"image":user.profile_picture,"stroke":3,"progress":user.progress_bar,"strokeColor":user.progress_bar < 100 ? '#6dd230' : '#fe4d97',"innerStrokeColor":"#ffffff","radius":25}})})],2)]}}],null,true)}),_c('Column',{attrs:{"header":_vm.minimized.indexOf(u.uid) < 0 ? 'Date Range' : '',"styles":{ width: '21%' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_c('div',[(_vm.job && _vm.job.client && _vm.job.is_template === 0)?[_vm._v(" "+_vm._s(_vm.dateRange(slotProps.data))+" "),_c('div',{staticClass:"flex align-items-center"},[_c('div',{class:'date-range-dot ' +
                            (slotProps.data.date_due?.tag_class === 'green'
                              ? 'green-active'
                              : slotProps.data.date_due?.tag_class)}),_c('span',{staticClass:"font-light pl-1"},[_vm._v(" "+_vm._s(slotProps.data.date_due?.time_until)+" ")])])]:[_c('div',[_vm._v("Not Scheduled")]),_c('div',{staticClass:"font-light"},[_vm._v("0 Days")])]],2)]}}],null,true)}),_c('Column',{attrs:{"headerStyle":{ textWrap: 'nowrap' },"header":_vm.minimized.indexOf(u.uid) < 0
                    ? 'Time'
                    : u.tasks.length +
                      ' task' +
                      (u.tasks.length == 1 ? '' : 's'),"field":"active","styles":{ width: '20%' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_c('div',[_vm._v(" "+_vm._s(slotProps.data.hours ? slotProps.data.hours : "00:00")+" / "+_vm._s(slotProps.data.estimated_hours)+" "),_c('div',{staticClass:"flex align-items-center mt-1"},[(_vm.job && _vm.job.client && _vm.job.is_template === 0)?_c('div',{staticClass:"surface-300 border-round overflow-hidden w-full",staticStyle:{"height":"6px"}},[_c('div',{staticClass:"h-full",style:(_vm.getProgressStyle(slotProps.data))})]):_vm._e()])])]}}],null,true)})],1):_vm._e()],1)]):_vm._e()]):_vm._e(),_c('ContextMenu',{ref:'gm-'+u.uid,refInFor:true,attrs:{"popup":true,"model":_vm.groupMenuItems}})],1)}),0),_c('ContextMenu',{ref:"menuSchedule",attrs:{"popup":true,"model":_vm.menuTaskUsers}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }